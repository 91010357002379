import React, { Component } from 'react';
import {
    Redirect, Router, Link
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import './home.css'
import axios from 'axios'

import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Banner from '../Components/Banner';
import ListVertical from '../Components/DashboardListVertical';
import Mitra from '../Components/Mitra'


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

import Cookies from 'universal-cookie';


// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader type="ThreeDots" color={'#3DADAA'} height="60" width="60" />
        </div>
    )
}

const cookies = new Cookies();
class Home extends Component {
    // Adds a class constructor that assigns the initial state values:
    constructor() {
        super()
        this.state = {
            is_guest: false,
            user_id: null,
            banners: [],
            banner: '',
            banner_title: '',
            banner_desc: '',
            banner_button: '',
            categories: [],
            category_id: null,
            total_zakat: 0,
            total_donasi: 0,
            total_donatur: 0,

            donate_link: '/',
            go_donate_banner: false,
            go_zakat: false,
            go_donate: false,
            go_detail_program: false,
            go_detail_update: false,
            go_program: false,

            seo_key: '',

            link_youtube: '',
            programs: [],
            kisah_sukses: [],
            program_infaq: [],
            program_zakat: [],
            updateArtikel: [],
            slider: [],

            loading: true,
            content_loading: true,
            isAvailable: ''
        }

        this.timer = null
    }

    // This is called when an instance of a component is being created and inserted into the DOM.
    componentDidMount() {
        this.getId()
        if (cookies.get('id_yayasan') != null) {
            this.getContent()
        }

        // if (this.props.location.state) {
        //     console.log('baru', this.props.location.state.trigger)
        //     location.reload()
        // }
        // console.log(this.state.link_youtube != '')
        // if(this.state.link_youtube != ''){

        // }
    }

    getId() {
        if (cookies.get('id_yayasan') == null) {
            axios.get(Constant.GET_YAYASAN)
                .then(res => {
                    var idc = res.data.data.id;
                    cookies.set('id_yayasan', idc);
                    // console.log('id_yayasn', cookies.get('id_yayasan'));
                    this.getContent()
                })
        }
    }

    getDashboardBanner() {
        // get banner
        axios.get(Constant.GET_DASHBOARD_BANNER + cookies.get('id_yayasan'))
            .then(response => {
                // console.log(response.data)
                this.setState({
                    banners: response.data.data,
                    banner: Constant.GET_DASHBOARD_BANNER_SLIDER + response.data.data[0].id,
                    banner_title: response.data.data[0].judul,
                    banner_desc: response.data.data[0].deskripsi,
                    banner_button: response.data.data[0].title_button,
                    donate_link: response.data.data[0].link
                });
                // console.log('tes2', response.data.data);
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getCategories() {
        // get categories
        axios.get(Constant.GET_CATEGORIES)
            .then(response => {
                // console.log(response.data)
                this.setState({ categories: response.data.data });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getStatistik() {
        //zakat
        axios.get(Constant.GET_TOTAL_ZAKAT)
            .then(response => {
                // console.log(response.data)
                this.setState({ total_zakat: response.data.total })
            })
            .catch(error => {
                console.log(error)
            })

        //donasi
        axios.get(Constant.GET_TOTAL_DONASI)
            .then(response => {
                // console.log(response.data)
                this.setState({ total_donasi: response.data.total })
            })
            .catch(error => {
                console.log(error)
            })

        //donatur
        axios.get(Constant.GET_TOTAL_DONATUR)
            .then(response => {
                // console.log(response.data)
                this.setState({ total_donatur: response.data.total })
            })
            .catch(error => {
                console.log(error)
            })
    }
    getContent() {
        trackPromise(
            axios.get(Constant.GET_DASHBOARD_CONTENT + cookies.get('id_yayasan'))
                .then(response => {
                    // console.log(response.data)
                    this.setState({
                        link_youtube: response.data.linkYoutube,
                        programs: response.data.program,
                        kisah_sukses: response.data.kisah_sukses,
                        program_infaq: response.data.program_infaq,
                        program_zakat: response.data.program_zakat,
                        updateArtikel: response.data.updateArtikel,
                        slider: response.data.slider
                    })

                    this.getDashboardBanner()
                    this.getCategories()
                    this.getStatistik()
                    this.setState({
                        loading: false,
                        content_loading: false
                    })
                    this.infaqClicked()
                }).catch(error => {
                    console.log(error)
                })
        )

    }


    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }

    nFormatter(num, digits) {
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "B" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret',
                'April', 'Mei', 'Juni', 'Juli',
                'Agustus', 'September', 'Oktober',
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", "
                + new_date.getDate() + " "
                + months[new_date.getMonth()] + " "
                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    infaqClicked() {
        document.getElementById("tab-infaq").classList.remove("item-tab");
        document.getElementById("tab-zakat").classList.add("item-tab");
        document.getElementById("tab-zakat").classList.remove("item-tab-active");
        document.getElementById("infaq-scroller").classList.remove("hide");
        document.getElementById("zakat-scroller").classList.add("hide");
    }
    zakatClicked() {
        document.getElementById("tab-infaq").classList.add("item-tab");
        document.getElementById("tab-infaq").classList.remove("item-tab-active");
        document.getElementById("tab-zakat").classList.add("item-tab-active");
        document.getElementById("tab-zakat").classList.remove("item-tab");
        document.getElementById("zakat-scroller").classList.remove("hide");
        document.getElementById("infaq-scroller").classList.add("hide");
    }
    newTabClickedW() {
        let linkWhatsapp = window.open('https://api.whatsapp.com/send?phone=62816593922&text=Assalamualaikum%20Teman%20Peduli&source=&data=&app_absent=', '_blank')
        linkWhatsapp.focus()
    }
    newTabClickedI() {
        let linkInstagram = window.open('https://www.instagram.com/temanpedulicom/', '_blank')
        linkInstagram.focus()
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const {
            banners, banner, banner_title, banner_desc, banner_button,
            categories, category_id, go_donate_banner, donate_link, content_loading,
            total_zakat, total_donasi, total_donatur, go_zakat, go_donate, seo_key, go_detail_program, go_detail_update, go_program,
            link_youtube, kisah_sukses, program_infaq, program_zakat, updateArtikel, programs, slider, is_guest, user_id
        } = this.state


        if (this.props.match.url === "/home")
            window.location.href = "/"


        if (go_donate_banner)
            return <Redirect exact to={donate_link} />

        if (go_zakat)
            return <Redirect exact to="/zakat" />

        else if (go_detail_program)
            return <Redirect exact to={`/detailprogram/${seo_key}`} />

        else if (go_detail_update)
            return <Redirect exact to={`/update/${seo_key}`} />

        else if (go_donate)
            return <Redirect exact to={`/donateform/${seo_key}`} />

        else if (go_program)
            return <Redirect exact to='/prog' />

        if (category_id)
            return <Redirect exact to={{
                pathname: "/programs",
                state: { category_id: category_id }
            }}
            />

        return (
            <div id="page">
                <Header />

                {content_loading === false &&
                    <>
                        <Banner slideData={slider} />
                        <div className="page-content">
                            <ListVertical title="Program" link="programs" data={programs} />

                            <div className="content bottom-10 top-30">
                                <h5 className="float-left font-600">Dokumentasi Kegiatan Kami</h5>
                                <div className="clear"></div>
                            </div>
                            <div data-height="350" className="caption caption-margins round-small shadow-small bottom-30" style={{ height: 350 + 'px' }}>
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/AtzK4B4bzfo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                                {/* <iframe width="100%" height="100%" src={link_youtube} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe> */}
                            </div>
                            <Mitra />
                        </div>
                    </>
                }



                <Footer /><br />
            </div>
        )
    }
}

export default Home