import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../../Constant'
import axios from 'axios'

class ZakatPenghasilan extends Component {
    constructor() {
        super()
        this.state = {
            penghasilan_perbulan: 0,
            penghasilan_tambahan_perbulan: 0,
            pengeluaran_pokok_perbulan: 0,
            harga_beras_perkilo: 0,
            kg_beras: 0,
            nishab: 0,
            jumlah_bulan_bayar: 1,
            total_zakat: 0,

            without_calculator: false,
            niat: false,
            go_zakat: false
        }

        this.timer = null
    }

    componentDidMount() {
        this.calculateZakat()
        this.getWeightRice()
    }

    onAmountChanged(e) {
        clearTimeout(this.timer)

        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })

        this.timer = setTimeout(() => {
                        if (name === "harga_beras_perkilo")
                            this.calculateNishab()
                        else if (name !== "total_zakat")
                            this.calculateZakat()
                    }, 250)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)

            this.calculateNishab()
            this.calculateZakat()
        }
    }
    handleCheckBox(e) {
        const { name, checked } = e.target
        this.setState({ [name]: checked })

        if (name === "without_calculator") this.withWithoutCalculator(checked)
    }
    getWeightRice(){
        const {
            kg_beras
        } = this.state

        axios.get(Constant.GET_PARAMETER + 'qty_beras',
        ).then(response => {
            // console.log(response.data.data.value)
            this.setState({kg_beras: response.data.data.value})
        }).catch(error => {
            console.log(error)
        })
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    calculateZakat() {
        const {
            penghasilan_perbulan, penghasilan_tambahan_perbulan,
            pengeluaran_pokok_perbulan, jumlah_bulan_bayar
        } = this.state
        let total = (penghasilan_perbulan + penghasilan_tambahan_perbulan - pengeluaran_pokok_perbulan) * (2.5/100) * jumlah_bulan_bayar
        console.log(total)
        this.setState({ 
            total_zakat: (total > 0) ? total : 0
        })
    }
    calculateNishab() {
        const { harga_beras_perkilo, kg_beras } = this.state
        const qty_beras = kg_beras
        let nishab = harga_beras_perkilo * qty_beras

        this.setState({ nishab })
    }
    withWithoutCalculator(without_calculator) {
        let form = document.getElementById("form_hide")
        let zakat = document.getElementById("total_zakat")

        if (without_calculator) {
            form.style.display = "none"
            zakat.removeAttribute("readonly")
            zakat.classList.remove("readonly")
        } else {
            form.style.display = "block"
            zakat.setAttribute("readonly", "")
            zakat.classList.add("readonly")
        }
    }
    validateZakat() {
        const { penghasilan_perbulan, penghasilan_tambahan_perbulan, pengeluaran_pokok_perbulan, nishab, total_zakat, niat, without_calculator } = this.state

        if (without_calculator) {
            if (niat)
                this.setState({ go_zakat: true })
            else
                alert("Harap baca dan centang niat zakat!")

        } else {
            let sisa_uang = (penghasilan_perbulan + penghasilan_tambahan_perbulan - pengeluaran_pokok_perbulan);
            // console.log(sisa_uang, nishab)
            if (sisa_uang >= nishab && niat)
                this.setState({ go_zakat: true })
            else if (sisa_uang < nishab)
                alert("Anda Belum Wajib Zakat")
            else if (!niat)
                alert("Harap baca dan centang niat zakat!")
        }
    }

    render() {
        const {
            penghasilan_perbulan, penghasilan_tambahan_perbulan,
            pengeluaran_pokok_perbulan, harga_beras_perkilo,
            nishab, jumlah_bulan_bayar, total_zakat, go_zakat, kg_beras
        } = this.state

        if (go_zakat)
            return <Redirect exact to={{
                            pathname: "/zakat/form-zakat",
                            state: { 
                                category: "Zakat Penghasilan",
                                total_zakat: total_zakat
                            }
                        }}
                    />

        return(
        <section id="penghasilan" className="zakat-box">
            <p className="label-form">Perhitungan Zakat Penghasilan</p>
            <hr/>
            <input 
                type="checkbox"
                id="check-without_calculator" 
                name="without_calculator"
                onClick={(e) => this.handleCheckBox(e)}
            />
            <label className="label-form" htmlFor="check-without_calculator">
                SAYA PUNYA PERHITUNAN SENDIRI (TANPA KALKULATOR)
            </label>
            <hr/>

            <div id="form_hide">
                <p className="label-form">Penghasilan Per Bulan</p>
                <input 
                    type="text" 
                    name="penghasilan_perbulan"
                    id="penghasilan_perbulan"
                    className="input-form"
                    value={this.numberFormat((penghasilan_perbulan)?penghasilan_perbulan:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">Penghasilan Tambahan Per Bulan</p>
                <input 
                    type="text"
                    name="penghasilan_tambahan_perbulan"
                    id="penghasilan_tambahan_perbulan"
                    className="input-form"
                    value={this.numberFormat((penghasilan_tambahan_perbulan)?penghasilan_tambahan_perbulan:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">Pengeluaran Pokok Per Bulan</p>
                <input 
                    type="text"
                    name="pengeluaran_pokok_perbulan"
                    id="pengeluaran_pokok_perbulan"
                    className="input-form"
                    value={this.numberFormat((pengeluaran_pokok_perbulan)?pengeluaran_pokok_perbulan:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">Harga Beras (/kg)</p>
                <input 
                    type="text"
                    name="harga_beras_perkilo"
                    id="harga_beras_perkilo"
                    className="input-form"
                    value={this.numberFormat((harga_beras_perkilo)?harga_beras_perkilo:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">NISHAB (HARGA BERAS x {kg_beras} KG)</p>
                <input 
                    type="text"
                    name="nishab"
                    id="nishab"
                    className="input-form readonly"
                    value={this.numberFormat((nishab)?nishab:0)}
                    readOnly
                />

                <p className="label-form">Jumlah Bulan Yang Akan Dibayarkan Zakatnya</p>
                <input 
                    type="text"
                    name="jumlah_bulan_bayar"
                    id="jumlah_bulan_bayar"
                    className="input-form"
                    value={(jumlah_bulan_bayar)?jumlah_bulan_bayar:0}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />
            </div>

            <p className="label-form">Besar Zakat Hasil Perhitungan</p>
            <input 
                type="text"
                name="total_zakat"
                id="total_zakat"
                className="input-form readonly"
                value={this.numberFormat((total_zakat)?total_zakat:0)}
                onChange={(e) => this.onAmountChanged(e)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                readOnly
            />

            <input 
                type="checkbox"
                id="check-niat" 
                name="niat"
                onClick={(e) => this.handleCheckBox(e)}
            />
            <label className="label-form" htmlFor="check-niat">
                Bismillah. Saya serahkan zakat saya kepada Yayasan Teman Peduli agar dapat di kelola dengan sebaik baiknya sesuai dengan ketentuan syariat agama.
            </label>
            <br/><br/>

            <a
                className="btn-dns" 
                style={{ padding: "10px" }} 
                onClick={() => this.validateZakat()}
            >
                <p>BAYAR</p>
            </a>
            <div className="padding-scroller">
                    
            </div>
        </section>
        )
    }
}

export default ZakatPenghasilan