import React, { Component } from 'react'
import {
  Redirect,
  Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Mitra from '../Components/Mitra';

const cookies = new Cookies();
class Hubungi extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false
        }
    }

    componentDidMount() {
        // this.ifLogin()
    }

    newTabClickedW(){
        let linkWhatsapp = window.open('https://api.whatsapp.com/send?phone=62816593922&text=Assalamualaikum%20Teman%20Peduli&source=&data=&app_absent=', '_blank')
        linkWhatsapp.focus()
    }
    newTabClickedI(){
        let linkInstagram = window.open('https://www.instagram.com/temanpedulicom/', '_blank')
        linkInstagram.focus()
    }

    handleCheck(e){
        console.log(e)
    }

    render() {
        const { 
           go_home
        } = this.state
        
        if (this.props.match.url === "/hub")
            window.location.href = "/hubungi"
        if (go_home)
            return <Redirect exact to='/home' />

        return (
        <div className="page-content">
            <Header />

            <div className="content-boxed">
                {/* <center>
                    <div style={{width: 100+'px'}}>
                        <img src="/hub.png" style={{marginTop: 20+'px', width: 50+'px'}}/>
                    </div>
                </center><br/> */}
                <center>
                    <h5 className="notif-transaksi">Teman Peduli</h5>
                    <div className="kontak-alamat">
                        <p>Jl. Jend. H. Amir Machmud No.260B, Sukaraja, Kec. Cicendo, Kota Bandung, Jawa Barat 40175 - Indonesia (Basecamp)</p>
                    </div>
                </center><br/>

                {/* <a className="kontak" onClick={() => this.newTabClickedI()}>Instagram</a>
                <a className="kontak" onClick={() => this.newTabClickedW()}>WhatsApp</a> */}
                
                <center>
                    <h5 className="notif-transaksi-hub">Hubungi Kami</h5>
                </center>
                <table>
                    <thead>
                        <tr>
                            <td>
                                <div className="float-right">
                                    <a style={{marginRight: 10+'px'}} href="#" onClick={() => this.newTabClickedW()}>
                                        <img src="/wa2.png" style={{maxWidth: '50px'}}/>
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div className="float-left">
                                <a style={{marginLeft: 10+'px'}} href="#" onClick={() => this.newTabClickedI()}>
                                        <img src="/ig2.png" style={{maxWidth: '50px'}}/>
                                    </a>  
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>
                <br />
            </div>

            <Mitra/>

            <Footer/>
        </div>
        )
    }
}

export default Hubungi