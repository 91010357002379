// Import React and Component
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

import withClearCache from './ClearCache';

// Import CSS from App.css
// import './App.css'
// Import the Home component to be used below
import Home from './Home/Home'

import Hubungi from './Hubungi/HubungiKami'

import Program from './Program/Program'
import DetailProgram from './Program/Detail/DetailProgram'
import Donate from './Program/Donate/Donate'

import EventProgram from './Event/Event';
import EventProgramDetail from './Event/Detail';
import EventProgramRegistrasi from './Event/Registrasi';
import Bagan from './Event/Bagan';

import Zakat from './Zakat/Zakat'
import HitungZakat from './Zakat/Hitung/HitungZakat'
import TunaikanZakat from './Zakat/Tunaikan/TunaikanZakat'

import Qurban from './Qurban/Qurban'
import Aqiqah from './Aqiqah/Aqiqah'

import RiwayatDonasi from './Riwayat/RiwayatDonasi'
import RiwayatZakat from './Riwayat/RiwayatZakat'
import RiwayatQurban from './Riwayat/RiwayatQurban'

import Login from './Login/Login'
import Logout from './Login/Logout'
import Daftar from './Login/Daftar'
import LupaPassword from './Login/LupaPassword'

import AfterPay from './Notification/AfterPay/AfterPay'

import Update from './Update/Update'
import DetailUpdate from './Update/Detail/DetailUpdate'

import ProfileMizan from './ProfileMizan/ProfileMizan'

import Profile from './Profile/Profile'
import GantiPassword from './Profile/GantiPassword'

import Notification from './Notification/Notification/Notification'

import PaymentMethod from './Xendit/PaymentMethod'

import RekeningDonasi from './RekeningDonasi/RekeningDonasi'
import KantorCabang from './KantorCabang/KantorCabang'
import SyaratDanKetentuan from './SyaratDanKetentuan/SyaratDanKetentuan'
import KetentuanPrivasi from './KetentuanPrivasi/KetentuanPrivasi'
import LaporanAudit from './LaporanAudit/LaporanAudit'

import Merchandise from './Merchandise/Merchandise'
import DetailMerchandise from './Merchandise/DetailMerchandise'
import CheckoutMerchandise from './Merchandise/Checkout'
import PreorderMerchandise from './Merchandise/Preorder'

// import firebase from './firebase'
// import '@firebase/messaging';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />
}

function MainApp(props) {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />

        {/* <Route exact path="/prog" component={Program} /> */}
        <Route exact path="/programs" component={Program} />
        <Route exact path="/programs/pencarian" component={Program} />

        <Route exact path="/event" component={EventProgram} />
        <Route exact path="/event/bagan/:seoKey" component={Bagan} />
        <Route exact path="/event/registrasi/:seoKey" component={EventProgramRegistrasi} />
        <Route exact path="/event/:seoKey" component={EventProgramDetail} />

        <Route exact path="/detail/:seoKey" component={DetailProgram} />
        <Route exact path="/detailprogram/:seoKey" component={DetailProgram} />
        <Route exact path="/detailprogram/:seoKey/:agency" component={DetailProgram} />

        <Route exact path="/donateform/:seoKey" component={Donate} />
        <Route exact path="/donateform/:seoKey/:agency" component={Donate} />

        <Route exact path="/zakat" component={Zakat} />
        <Route exact path="/zakat/kalkulator" component={HitungZakat} />
        <Route exact path="/zakat/form-zakat" component={TunaikanZakat} />

        <Route exact path="/form-qurban" component={Qurban} />
        <Route exact path="/aqiqah" component={Aqiqah} />

        <Route exact path="/riwayat_donasi" component={RiwayatDonasi} />
        <Route exact path="/riwayat_zakat" component={RiwayatZakat} />
        <Route exact path="/riwayat_qurban" component={RiwayatQurban} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/daftar" component={Daftar} />
        <Route exact path="/lupa_password" component={LupaPassword} />

        <Route exact path="/notif/notif-wait" component={AfterPay} />

        <Route exact path="/update" component={Update} />
        <Route exact path="/update/pencarian" component={Update} />
        <Route exact path="/update/:seoKey" component={DetailUpdate} />

        <Route exact path="/tentang" component={ProfileMizan} />

        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/ganti_password" component={GantiPassword} />

        <Route exact path="/notification" component={Notification} />

        <Route exact path="/payment_method" component={PaymentMethod} />

        <Route exact path="/rekening-donasi" component={RekeningDonasi} />
        <Route exact path="/kantor-cabang" component={KantorCabang} />
        <Route exact path="/syarat-dan-ketentuan" component={SyaratDanKetentuan} />
        <Route exact path="/ketentuan-privasi" component={KetentuanPrivasi} />
        <Route exact path="/laporan-audit" component={LaporanAudit} />

        <Route exact path="/hubungi" component={Hubungi} />
        <Route exact path="/hub" component={Hubungi} />

        <Route exact path="/merchandise" component={Merchandise} />
        <Route exact path="/merchandise/:productKey" component={DetailMerchandise} />
        <Route exact path="/merchandise/checkout/:productKey" component={CheckoutMerchandise} />
        <Route exact path="/merchandise/preorder/:productKey" component={PreorderMerchandise} />

      </Switch>

    </Router>
  );
}

export default App;