import React, { Component } from 'react';
import {
    Redirect,
} from 'react-router-dom'
import { MetodePembayaran, Input, Nominal } from '../Components/FormComponents';
import * as Constant from '../Constant'
import axios from 'axios'
import Loader from 'react-loader-spinner';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import Cookies from 'universal-cookie';
import Header from '../Components/Header';

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader type="ThreeDots" color={'white'} height="40" width="40" />
        </div>
    )
}



const cookies = new Cookies();

class Registrasi extends Component {
    constructor() {
        super()
        this.state = {
            key_yayasan: Constant.KEY_YAYASAN,
            user_id: null,
            events: [],
            donation: 0,

            nama_tim: '',
            logo_tim: '',
            id_leader: '',
            nickname_leader: '',
            nama_leader: '',
            nomor_leader: '',
            email_leader: '',
            jumlah_anggota: '',

            nama_logo: '',

            phone: '',
            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            alfamart_code: 'ALFAMARTCODEHERE!!!',

            go_back: false,
            go_paid: false,

            go_payment: false,
            checkout_url: '',
            go_via_checkout: false,

            donation_type: [],
            nominals: [],
            metode_pembayaran: 'Pilih Metode Pembayaran',
            btn_register: 'DAFTAR',
            setIsOpen: false,

            //PaymentMethod
            payment_method: null,
            vendor: null,
            selected_method: null,
            selected_vendor: null
        }
        this.sendRegistration = this.sendRegistration.bind(this);
    }

    componentDidMount() {
        this.getEventDetail()
    }

    getEventDetail() {
        let { match: { params } } = this.props
        axios.get(Constant.GET_EVENT_PROGRAMS_DETAIL + params.seoKey).then(
            response => {
                this.setState({
                    events: response.data.data,
                    image_link: Constant.GET_EVENT_PROGRAMS_IMAGE + response.data.data.event_program_id
                });
                this.getDonationNominals()
            }
        ).catch(error => {
            console.log(error)
        })
    }

    getDonationNominals() {
        const { events } = this.state
        let id_event = events.id
        axios.get(Constant.GET_REGISTRATION_NOMINAL + id_event).then(
            response => {
                console.log(response.data)
                if (response.data.data) {
                    this.setState({
                        donation_type: response.data.data
                    });
                } else {
                    this.getNominal()
                }
            }
        )
    }

    getNominal() {
        axios.get(Constant.GET_NOMINAL_BUTTON).then(
            response => {
                this.setState({ nominals: response.data.data })
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    callbackNominal = (nominal) => {
        this.setState({ donation: nominal });
    }

    callbackVendor = (callVendor, callMethod) => {
        this.setState({ selected_method: callMethod, selected_vendor: callVendor });
    }

    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }

    handleFileInputChange(event) {
        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        this.setState({ logo_tim: event.target.files[0], nama_logo: event.target.files[0].name })
    }

    commitRegister(event) {
        if (!event.detail || event.detail == 1) {
            document.getElementById("btnRegister").disabled = true;
            this.registerEvent()
        }
    }

    registerEvent() {
        const {
            user_id, events, donation, vendor, payment_method,
            nama_tim, nama_leader, logo_tim, email_leader, nomor_leader, jumlah_anggota,
            selected_vendor, donation_type, phone
        } = this.state

        let url = this.props.location.pathname.split('/')

        let logo = true;
        if(logo_tim === null || logo_tim === '') {
            logo = window.confirm('Apakah anda yakin tidak akan mengisi logo tim?');
        }
        if (selected_vendor !== null) {
            if (selected_vendor.payment_type !== "e_wallet") {
                if (donation_type !== null && donation < donation_type.value) {
                    alert('Donasi Minimal Rp.' + donation_type.value)
                    document.getElementById("btnRegister").disabled = false;
                } else if (!donation || donation < 10000) {
                    alert('Donasi untuk Metode Pembayaran Ini Minimal Rp. 10.000')
                    document.getElementById("btnRegister").disabled = false;
                } else if (nama_tim === '' || nama_tim === null) {
                    alert('Nama Tim Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (nama_leader === '' || nama_leader === null) {
                    alert('Nama Leader Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (nomor_leader === '' || nomor_leader === null) {
                    alert('Nomor Leader Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (email_leader === '' || email_leader === null) {
                    alert('Email Leader Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (jumlah_anggota === '' || jumlah_anggota === null || jumlah_anggota === 0) {
                    alert('Jumlah Anggota Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (payment_method === 0) {
                    alert('Pilih Metode Pembayaran')
                    document.getElementById("btnRegister").disabled = false;
                } else if(logo === false) {
                    alert('Silahkan isi logo tim');
                    document.getElementById("btnRegister").disabled = false;
                } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email_leader)) {
                    let vendor_name = selected_vendor.payment_name
                    if (vendor_name == "OVO") {
                        if (phone == null || phone == "") {
                            alert('Harap Isi Nomor Telepon E-Wallet Anda')
                            document.getElementById("btnRegister").disabled = false;
                        } else {
                            document.getElementById("ovo_popup").style.display = "block"
                            this.sendRegistration()
                        }
                    } else {
                        this.sendRegistration()
                    }
                } else {
                    alert('Email Tidak Valid')
                    document.getElementById("btnRegister").disabled = false;
                }
            } else {
                if (donation_type !== null && donation < donation_type.value) {
                    alert('Donasi Minimal Rp.' + donation_type.value)
                    document.getElementById("btnRegister").disabled = false;
                } else if (!donation || donation < 10000) {
                    alert('Donasi untuk Metode Pembayaran Ini Minimal Rp. 10.000')
                    document.getElementById("btnRegister").disabled = false;
                } else if (nama_tim === '' || nama_tim === null) {
                    alert('Nama Tim Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (nama_leader === '' || nama_leader === null) {
                    alert('Nama Leader Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (nomor_leader === '' || nomor_leader === null) {
                    alert('Nomor Leader Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (email_leader === '' || email_leader === null) {
                    alert('Email Leader Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (jumlah_anggota === '' || jumlah_anggota === null || jumlah_anggota === 0) {
                    alert('Jumlah Anggota Tidak Boleh Kosong')
                    document.getElementById("btnRegister").disabled = false;
                } else if (payment_method === 0) {
                    alert('Pilih Metode Pembayaran')
                    document.getElementById("btnRegister").disabled = false;
                } else if(logo === false) {
                    alert('Silahkan isi logo tim');
                    document.getElementById("btnRegister").disabled = false;
                }else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email_leader)) {
                    let vendor_name = selected_vendor.payment_name
                    if (vendor_name == "OVO") {
                        if (phone == null || phone == "") {
                            alert('Harap Isi Nomor Telepon E-Wallet Anda')
                    document.getElementById("btnRegister").disabled = false;
                        } else {
                            document.getElementById("ovo_popup").style.display = "block"
                            this.sendRegistration()
                        }
                    } else {
                        this.sendRegistration()
                    }
                } else {
                    alert('Email Tidak Valid')
                    document.getElementById("btnRegister").disabled = false;
                }
            }
        }
    }

    sendRegistration() {
        const {
            user_id, events, donation, vendor, payment_method,
            nama_tim, logo_tim, nama_leader, email_leader, nomor_leader, jumlah_anggota, id_leader, nickname_leader,
            card_number, exp_month, exp_year, cvn, selected_vendor, phone
        } = this.state

        this.setState({
            btn_register: ''
        });

        let that = this;

        let nomor_hp = null
        if (selected_vendor.payment_name === 'OVO' || selected_vendor.payment_name === 'DANA') {
            nomor_hp = phone
        } else {
            nomor_hp = nomor_leader
        }

        let metode_pembayaran = null
        if (selected_vendor.payment_type === 'virtual_account') {
            metode_pembayaran = selected_vendor.midtrans_code
        } else if (selected_vendor.payment_name === "Alfamart") {
            metode_pembayaran = selected_vendor.payment_name.toUpperCase()
        } else {
            metode_pembayaran = selected_vendor.payment_name
        }

        let array = []
        if (selected_vendor.payment_type === 'credit_card') {
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }
        let participant_data = new FormData();
        participant_data.append("event_program_id", events.event_program_id);
        participant_data.append("team_logo", logo_tim);
        participant_data.append("team_name", nama_tim);
        participant_data.append("id_leader", id_leader);
        participant_data.append("nickname_leader", nickname_leader);
        participant_data.append("leader_name", nama_leader);
        participant_data.append("total_donation", donation);
        participant_data.append("total_member", jumlah_anggota);
        participant_data.append("key_yayasan", Constant.KEY_YAYASAN);
        participant_data.append("nominal", donation);
        participant_data.append("nama_lengkap", nama_leader);
        participant_data.append("email", email_leader);
        participant_data.append("no_handphone", nomor_leader);
        participant_data.append("program_id", events.program_id);
        participant_data.append("vendor", selected_vendor.vendor);
        participant_data.append("payment_type", selected_vendor.payment_type);
        participant_data.append("payment_method", metode_pembayaran);
        participant_data.append("device", 'PWA');

        trackPromise(
            axios.post(Constant.STORE_EVENT_PARTICIPANT, participant_data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(
                response => {
                    if (response.data.snap_token !== null) {
                        cookies.remove('linkBefore')
                        cookies.remove('customerBio')
                        cookies.remove('selected_method')
                        cookies.remove('vendor')

                        window.snap.pay(response.data.snap_token, {
                            onSuccess: function (result) {
                                let pisah_url = window.location.href.split('/')
                                window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                            },
                            onPending: function (result) {
                                let pisah_url = window.location.href.split('/')
                                window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                            },
                            onError: function (result) {
                                axios.post(Constant.DELETE_EVENT_PARTICIPANT, {
                                    event_id: events.event_program_id,
                                    id_leader: id_leader
                                });
                                console.log('gagal')
                                document.getElementById("btnRegister").disabled = false;
                            },
                            onClose: function (result) {
                                let id = ''
                                if (selected_vendor.vendor === 'midtrans') {
                                    id = response.data.snap_token
                                }
                                const data_send = {
                                    iDtoken: id,
                                    status: 1,
                                    key_yayasan: Constant.KEY_YAYASAN
                                }

                                axios.post(Constant.DELETE_EVENT_PARTICIPANT, {
                                    event_id: events.event_program_id,
                                    id_leader: id_leader
                                });
                                axios.post(Constant.DELETE_SNAPTOKEN, data_send).then(
                                    response => {
                                        alert("Transaksi Dibatalkan")
                                        that.setState({
                                            btn_register: 'DAFTAR'
                                        })
                                        document.getElementById("btnRegister").disabled = false;
                                    }
                                ).catch(
                                    error => {
                                        console.log(error)
                                        document.getElementById("btnRegister").disabled = false;
                                    }
                                )

                            }


                        })
                    } else {
                        if (response.data.data_ew) {
                            window.location.href = response.data.data_ew.checkout_url
                        }
                        if (response.data.data_ro) {
                            this.setState({
                                alfamart_code: response.data.data_ro.payment_code
                            })
                            document.getElementById("alfamart_popup").style.display = "block"
                        }
                        document.getElementById("btnRegister").disabled = false;
                    }
                }
            )
        );
    }

    render() {
        const { match, match: { params } } = this.props
        const {
            events, image_link, donation_type, nominals, vendor, phone,
            nama_tim, logo_tim, nama_leader, nomor_leader, email_leader, nama_logo, jumlah_anggota, id_leader, nickname_leader,
            phone_number, email, go_back, go_paid, go_payment, btn_register, selected_vendor, card_number, exp_month, exp_year, cvn
        } = this.state
        return (
            <div className="page-content">
                <Header back_url={'/event'} />
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                        {events.judul}
                    </div>
                </div>

                <div className="content-boxed" style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <div className="content" id="program-list-content">
                        <div className="program-home-image" style={{ backgroundImage: `url(${image_link})` }}></div>
                        {/* <div className="program-home-image" style={{backgroundImage: `url(tp/banner.png)`}}></div> */}
                        <div className="program-desc">
                            <p style={{ marginBottom: 0 }}>Program yang di pilih :</p>
                            <b><p className="title-program">{events.judul}</p></b>
                        </div>
                    </div>
                </div>

                <div className="content-boxed">
                    <section id="donasi-box">
                        <Nominal label="Nominal Donasi (Rp)" donation_type={donation_type} nominal={nominals} callback={this.callbackNominal} />
                        <MetodePembayaran callback={this.callbackVendor} />
                        <div id="btn_for_login">
                            <br />
                            <center style={{ fontSize: 12 + 'px', color: '#8a8a8a' }}>
                                Silahkan isi data di bawah ini
                            </center>
                        </div>

                        <Input type="text" name="nama_tim" value={nama_tim} placeholder="Nama Tim" callback={this.callbackInput} />
                        <p>Logo Tim</p>
                        <div style={{ display: 'flex' }}>
                            <input style={{ width: '25%' }} type="file" name="logo_tim" placeholder="Logo Tim" onChange={(e) => this.handleFileInputChange(e)} accept=".png,.jpeg,.jpg" />
                            <label>{nama_logo}</label>
                        </div>
                        <Input type="number" name="id_leader" value={id_leader} placeholder="ID In-Game Leader" callback={this.callbackInput} />
                        <Input type="text" name="nickname_leader" value={nickname_leader} placeholder="Nickname Leader" callback={this.callbackInput} />
                        <Input type="text" name="nama_leader" value={nama_leader} placeholder="Nama Leader" callback={this.callbackInput} />
                        <Input type="number" name="nomor_leader" value={nomor_leader} placeholder="Nomor Leader" callback={this.callbackInput} />
                        <Input type="email" name="email_leader" value={email_leader} placeholder="Email Leader" callback={this.callbackInput} />
                        <Input type="number" name="jumlah_anggota" value={jumlah_anggota} placeholder="Jumlah Anggota" callback={this.callbackInput} />
                        {selected_vendor !== null && selected_vendor.payment_type === "virtual_account" &&
                            <>
                                <Input type="number" name="card_number" value={card_number} placeholder="Card Number" callback={this.callbackInput} />
                                <Input type="number" name="exp_month" value={exp_month} placeholder="Expired Month" callback={this.callbackInput} />
                                <Input type="number" name="exp_year" value={exp_year} placeholder="Expired Year" callback={this.callbackInput} />
                                <Input type="number" name="cvn" value={cvn} placeholder="CVN" callback={this.callbackInput} />
                            </>
                        }
                        <br />
                        <button id="btnRegister" className="donasi" style={{ cursor: 'pointer', width: '100%' }} onClick={this.commitRegister}>{btn_register}<LoadingIndicator /></button>
                    </section>
                </div>
            </div>
        )
    };
}

export default Registrasi;