import React, { Component } from 'react'
import {
  Redirect,
  Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'

//Loading
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader type="ThreeDots" color={'#3DADAA'} height="60" width="60" />
        </div>
    )
}
const cookies = new Cookies();
class PaymentMethod extends Component {
    constructor() {
        super()
        this.state = {
            vendors: [],
            vendors_child: [],
            selected_method: 0,
            selected_vendor: null,

            go_back: false
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
        this.getVendors()
        // console.log(cookies.get('linkBefore'))
    }

    getVendors(){
        const array = this.state.vendors
        trackPromise(
            Axios.get(Constant.GET_VENDORS)
            .then(response => {
                // this.setState({vendors: response.data.data})
                let res = response.data.data
                res.map((result, i) => {
                    Axios.get(Constant.GET_VENDORS_CHILD + result.id + '?yayasan=' + Constant.KEY_YAYASAN)
                        .then(response2 => {
                            if(response2.data.data.length > 0){
                                const array2 = {
                                    "id": result.id,
                                    "category": result.category,
                                    "anak": response2.data.data
                                }
                                array.push(array2)
                                this.setState({vendors: array})
                            }
                        }).catch(error2 => {
                            console.log(error2)
                        })
                })

                this.getVendorsChild()
            })
            .catch(error => {
                console.log(error)
            })
        )
        
    }
    getVendorsChild(){
        const { vendors } = this.state

        vendors.map((vendors, i) => {
            Axios.get(Constant.GET_VENDORS_CHILD + vendors.id + '?yayasan=' + Constant.KEY_YAYASAN)
                .then(response => {
                    this.setState({ vendors_child: response.data.data })
                })
                .catch(error => {
                    console.log(error)
                })  
        })
    }
    clickAction(v){
        // console.log(v)
        this.setState({
            selected_method: v.id,
            selected_vendor: v.vendor
        })

        cookies.set('selected_method', v.id);
        cookies.set('vendor', v);
        this.setState({ go_back: true })
    }
    goBack(){
        cookies.set('selected_method', null);
        cookies.set('vendor', null);
        this.setState({ go_back: true })
    }

    render() {
        const { 
            vendors, go_back
        } = this.state

        if(go_back)
            return <Redirect exact to={`${cookies.get('linkBefore')}`} />

        return (
            <div className="page-content">
                {/* <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left back" 
                            onClick={() => this.goBack()}>
                        </i>
                        Metode Pembayaran
                    </nav>
                </header> */}

                <div className="header header-fixed header-logo-app">
                    <div className="res">
                        <i id="ic-sidebar" className="fa fa-arrow-left back" onClick={() => this.goBack()} style={{padding: 20+"px", cursor: 'pointer'}}></i> Metode Pembayaran
                    </div>
                </div><br/><br/><br/>

                <div className="content-boxed">
                    {/* <center><p className="zakat-title" style={{marginTop: 10+'px'}}>Metode Pembayaran</p></center> */}
                    <LoadingIndicator />
                    {vendors.map((vendor, i) => {
                        return(
                            <div key={i} style={{margin: 10+"px", padding: 10+"px"}}>
                                <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} />
                                <h6 style={{color: '#000000'}}>{vendor.category}</h6>
                                {vendor.anak.map((child, ii) => {
                                    let foto = Constant.GET_PICT_VENDOR + child.id
                                    return(
                                        <div key={ii}>
                                            <table style={{marginBottom:0+'px'}}>
                                                <tbody>
                                                    <tr onClick={() => this.clickAction(child)} style={{ cursor: 'pointer' }}>
                                                        <td width="30%">
                                                            <img src={foto} style={{maxWidth: 80+'px'}}/>
                                                        </td>
                                                        <td style={{verticalAlign: 'middle'}}>
                                                            {child.payment_name}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })}

                                {/* <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} /> */}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default PaymentMethod