import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../../Constant'
import axios from 'axios'

class ZakatHarta extends Component {
    constructor() {
        super()
        this.state = {
            emas_gram: 0,
            perak_gram: 0,
            tunai_tabungan: 0,
            properti: 0,
            kendaraan: 0,
            saham: 0,
            harta_lain: 0,
            total_harta: 0,
            hutang: 0,
            total_zakat: 0,
            nishab: 0,
            
            gold_price: 0,
            silver_price: 0,
            
            without_calculator: false,
            niat: false,
            go_zakat: false
        }

        this.harga_emas = 662000
        this.harga_perak = 13200
        this.timer = null
    }

    componentDidMount() {
        this.calculateNishab()
        this.getGoldPrice()
        this.getSilverPrice()
    }

    onAmountChanged(e) {
        clearTimeout(this.timer)

        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })

        this.timer = setTimeout(() => {
                        if (name !== "total_zakat")
                            this.calculateZakat()
                    }, 250)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)

            this.calculateZakat()
        }
    }
    handleCheckBox(e) {
        const { name, checked } = e.target
        this.setState({ [name]: checked })

        if (name === "without_calculator") this.withWithoutCalculator(checked)
    }
    getGoldPrice(){
        const {
            gold_price
        } = this.state

        axios.get(Constant.GET_PARAMETER + 'harga_emas',
        ).then(response => {
            // console.log(response.data.data.value)
            this.setState({gold_price: response.data.data.value})
        }).catch(error => {
            console.log(error)
        })
    }
    getSilverPrice(){
        const {
            silver_price
        } = this.state

        axios.get(Constant.GET_PARAMETER + 'harga_perak',
        ).then(response => {
            // console.log(response.data.data.value)
            this.setState({silver_price: response.data.data.value})
        }).catch(error => {
            console.log(error)
        })
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    calculateZakat() {
        const {
            emas_gram, perak_gram, tunai_tabungan, properti,
            kendaraan, saham, harta_lain, hutang, gold_price, silver_price
        } = this.state

        // var total_harta_kotor  = (jumlah_emas*harga_emas) + (jumlah_perak*harga_perak) + jumlah_uang + jumlah_properti + jumlah_kendaraan + jumlah_saham + jumlah_lainnya;

        // var total_harta_bersih = (total_harta_kotor - kewajiban_hutang) * (2.5/100);

        let emas = emas_gram * gold_price
        let perak = perak_gram * silver_price

        let total_kotor = emas + perak + tunai_tabungan + properti + kendaraan + saham + harta_lain
        let total_bersih = (total_kotor - hutang) * (2.5/100)

        this.setState({ 
            total_harta: (total_kotor > 0) ? total_kotor : 0,
            total_zakat: (total_bersih > 0) ? total_bersih : 0,
            nishab: gold_price * 85
        })
    }
    calculateNishab() {
        // const {
        //     gold_price
        // } = this.state

        // let nishab = gold_price * 85
        // console.log(nishab)

        // this.setState({ nishab })
    }
    withWithoutCalculator(without_calculator) {
        let form = document.getElementById("form_hide")
        let form2 = document.getElementById("form_hide2")
        let zakat = document.getElementById("total_zakat")

        if (without_calculator) {
            form.style.display = "none"
            form2.style.display = "none"
            zakat.removeAttribute("readonly")
            zakat.classList.remove("readonly")
        } else {
            form.style.display = "block"
            form2.style.display = "block"
            zakat.setAttribute("readonly", "")
            zakat.classList.add("readonly")
        }
    }
    validateZakat() {
        const { nishab, total_zakat, niat, without_calculator } = this.state
        if (without_calculator) {
            if (niat)
                this.setState({ go_zakat: true })
            else
                alert("Harap baca dan centang niat zakat!")   
        } else {
            if (total_zakat >= nishab && niat)
                this.setState({ go_zakat: true })
            else if (total_zakat < nishab)
                alert("Anda Belum Wajib Zakat")
            else if (!niat)
                alert("Harap baca dan centang niat zakat!")
        }
    }

    render() {
        const {
            emas_gram, perak_gram, tunai_tabungan, properti,
            kendaraan, saham, harta_lain, total_harta, hutang,
            total_zakat, nishab, go_zakat, gold_price, silver_price
        } = this.state

        let total_zakat_bayar = total_zakat * (2.5/100)

        if (go_zakat)
            return <Redirect exact to={{
                            pathname: "/zakat/form-zakat",
                            state: { 
                                category: "Zakat Harta",
                                total_zakat: total_zakat_bayar
                            }
                        }}
                    />

        return(
        <section id="maal" className="zakat-box">
            <p className="label-form">Perhitungan Zakat Harta (Maal)</p>
            <hr/>
            <input 
                type="checkbox" 
                id="check-without_calculator" 
                name="without_calculator"
                onClick={(e) => this.handleCheckBox(e)}
            />
            <label className="label-form" htmlFor="check-without_calculator">
                SAYA PUNYA PERHITUNAN SENDIRI (TANPA KALKULATOR)
            </label>
            <hr/>


            <div id="form_hide">
                <p className="label-form">JUMLAH EMAS YANG TIDAK DI PAKAI (HARGA EMAS/GRAM RP. {gold_price})</p>
                <input 
                    type="text" 
                    name="emas_gram" 
                    id="emas_gram" 
                    className="input-form" 
                    value={this.numberFormat((emas_gram)?emas_gram:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">JUMLAH PERAK YANG TIDAK DI PAKAI (HARGA PERAK/GRAM RP. {silver_price})</p>
                <input 
                    type="text" 
                    name="perak_gram" 
                    id="perak_gram" 
                    className="input-form"
                    value={this.numberFormat((perak_gram)?perak_gram:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">UANG TUNAI, TABUNGAN, DEPOSITO, ATAU SEJENISNYA</p>
                <input 
                    type="text" 
                    name="tunai_tabungan" 
                    id="tunai_tabungan" 
                    className="input-form" 
                    value={this.numberFormat((tunai_tabungan)?tunai_tabungan:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">PROPERTI (TIDAK TERMASUK YANG DI TINGGALI)</p>
                <input 
                    type="text" 
                    name="properti" 
                    id="properti" 
                    className="input-form" 
                    value={this.numberFormat((properti)?properti:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">KENDARAAN (TIDAK TERMASUK YANG DI PAKAI UNTUK KEPERLUAN KELUARGA)</p>
                <input 
                    type="text" 
                    name="kendaraan" 
                    id="kendaraan" 
                    className="input-form" 
                    value={this.numberFormat((kendaraan)?kendaraan:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">SAHAM, SUKUK, DAN SURAT BERHARGA LAINNYA</p>
                <input 
                    type="text" 
                    name="saham" 
                    id="saham" 
                    className="input-form" 
                    value={this.numberFormat((saham)?saham:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">HARTA LAINNYA (KOLEKSI SENI, BARANG ANTIK, BARANG BERHARGA, DLL)</p>
                <input 
                    type="text" 
                    name="harta_lain" 
                    id="harta_lain" 
                    className="input-form" 
                    value={this.numberFormat((harta_lain)?harta_lain:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />

                <p className="label-form">TOTAL HARTA</p>
                <input 
                    type="text" 
                    name="total_harta" 
                    id="total_harta" 
                    className="input-form readonly" 
                    value={this.numberFormat((total_harta)?total_harta:0)}
                    readOnly 
                />

                <p className="label-form">KEWAJIBAN HUTANG JATUH TEMPO</p>
                <input 
                    type="text" 
                    name="hutang" 
                    id="hutang" 
                    className="input-form" 
                    value={this.numberFormat((hutang)?hutang:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />
            </div>

            <p className="label-form">HARTA YANG DI ZAKATKAN</p>
            <input 
                type="text" 
                name="total_zakat" 
                id="total_zakat" 
                className="input-form readonly" 
                value={this.numberFormat((total_zakat)?total_zakat:0)}
                onChange={(e) => this.onAmountChanged(e)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                readOnly 
            />

            <form id="form_hide2">
                <p className="label-form">NISAB ZAKAT HARTA (SETARA DENGAN 85 GR EMAS X HARGA EMAS)</p>
                <input 
                    type="text" 
                    name="nishab" 
                    id="nishab" 
                    className="input-form readonly" 
                    value={this.numberFormat((nishab)?nishab:0)}
                    readOnly 
                />
            </form>

            <input 
                type="checkbox"
                id="check-niat" 
                name="niat"
                onClick={(e) => this.handleCheckBox(e)}
            />
            <label className="label-form" htmlFor="check-niat">
                Bismillah. Saya serahkan zakat saya kepada Yayasan Teman Peduli agar dapat di kelola dengan sebaik baiknya sesuai dengan ketentuan syariat agama.
            </label>
            <br/><br/>

            <a 
                className="btn-dns" 
                style={{ padding: "10px" }} 
                onClick={() => this.validateZakat()}
            >
                <p>BAYAR</p>
            </a>
            <div className="padding-scroller">
                    
            </div>
        </section>
        )
    }
}

export default ZakatHarta