import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Login extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
            fcmToken: 'asdasdasdas',

            go_home: false
        }
    }

    componentDidMount() {
        this.doLogout()
    }

    doLogout(){
        const {
            go_home
        } = this.state

        const parameter = {
            fcmToken: cookies.get('token')
        }

        axios.post(Constant.LOGOUT, parameter, {
        }).then(response2 => {
            // console.log(response2)
            cookies.remove('user_id')
            cookies.remove('username')
            cookies.remove('token')

            this.setState({go_home: true})
        }).catch(error => {
            console.log(error)
        })
    }

    render(){
        const {
            go_home
        } = this.state
        
        if (go_home)
            return <Redirect exact to="/home" />
        return(
            <div>

            </div>
        )
    }
}

export default Login