import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import axios from 'axios'
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';


class Index extends Component {
    constructor() {
        super()
        this.state = {
            merchandise: [],
            loading: true,
            image_link: ''
        }
    }

    componentDidMount() {
        // window.onpopstate = (event) => {
        //     console.log('haiiii', event);
        // }
        this.getMerchandise()

        document.addEventListener('scroll', this.trackScrolling)

    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    getMerchandise() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.GET_MERCHANDISE_DETAIL + params.productKey)
                .then(response => {
                    let data = response.data.data;
                    this.setState({
                        merchandise: data,
                        loading: false,
                        image_link: Constant.GET_MERCHANDISE_IMAGE + data.id
                    })
                })
        )
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    render() {
        const { merchandise, loading, image_link } = this.state
        return (
            <>
                <div className="page-content">
                    <div className="header header-fixed header-logo-app header-auto-show">
                        <div className="res">
                            <Link to={'/merchandise'} >
                                <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                                {merchandise.product_name}
                            </Link>
                        </div>
                    </div>

                    <Header />

                    <br></br>

                    {loading == false &&
                        <div className="content-boxed" id="programs_scroller" style={{ marginTop: 0 }}>
                            <div className="content">
                                <div className="det-img-merch" style={{ backgroundImage: `url(${image_link})` }}></div>
                                <p className="det-nama-merch">{merchandise.product_name}</p>
                                <p>Harga Minimal</p>
                                <p className="det-harga-merch">Rp {this.numberFormat(merchandise.value)}</p>
                                <p className="desc-merch">{ReactHtmlParser(merchandise.product_description)}</p>
                            </div>
                        </div>
                    }

                    <div id="footer-menu" className="footer-menu-5-icons footer-menu-style-3">
                        <div className="res menu-footer">
                            <Link className="btn-merch" style={{ width: '90%' }} to={'/merchandise/checkout/'+merchandise.product_key}>Pesan Sekarang</Link>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default Index
