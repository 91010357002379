import React, { Component } from 'react'
import {
    Redirect, Link
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import axios from 'axios'
import Header from '../Components/Header'
import { Collapse } from 'react-collapse';

// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }}>
            <Loader type="Oval" color={'#3DADAA'} height="80" width="80" />
        </div>
    )
}


class DetailEvent extends Component {
    constructor() {
        super()
        this.state = {
            events: [],
            image_link: '',

            target: 0,
            collected: 0,
            percentage: 0,
            final_day: Date.now(),
            donatur: 0,

            total_donatur: 0,

            page: 1,
            last_page: 1,
            loading: true,

            go_back: false,
            go_donate: false,
            event_loading: true,

            collapse_perkembangan: false,
            collapse_donatur: false,

            event_avaibility: false,
            event_seo: null,
            program_seo: null
        }

        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        // window.onpopstate = (event) => {
        //     console.log('haiiii', event);
        // }

        this.checkEvent()
        this.getEventDetail()

        document.addEventListener('scroll', this.trackScrolling)

    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }


    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight &&
            this.state.page != this.state.last_page) {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({
                page: next,
                loading: true
            })
            // this.getDonaturs()
        }
    }

    checkEvent() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.CHECK_EVENT_AVAIBILITY + params.seoKey)
                .then(response => {
                    if (response.data.data !== null) {
                        this.setState({
                            event_avaibility: true,
                            event_seo: response.data.data.seo
                        })
                    }
                })
        );
    }

    getEventDetail() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.GET_EVENT_PROGRAMS_DETAIL + params.seoKey)
                .then(response => {
                    let data = response.data
                    let collected = parseInt(data.total_donation)

                    this.setState({
                        events: data.data,
                        image_link: Constant.GET_EVENT_PROGRAMS_IMAGE + data.data.event_program_id,
                        program_seo: data.program.seo,

                        collected: collected,

                        event_loading: false,
                        //final_day: tanggal_akhir,
                    });

                    if (response.data.program.program.donatur[0]) {
                        this.setState({
                            donatur: response.data.program.program.donatur[0].donatur,
                        })
                    } // console.log('tgl', final_day)
                    //this.getDonaturs()
                    //this.getInfoProgram()
                    //this.countDonatur()
                })
                // Catch any error here
                .catch(error => {
                    console.log(error)
                })
        )

    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret',
                'April', 'Mei', 'Juni', 'Juli',
                'Agustus', 'September', 'Oktober',
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", "
                + new_date.getDate() + " "
                + months[new_date.getMonth()] + " "
                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }
    newTabClickedW() {
        let linkWhatsapp = window.open('https://wa.me/?text=https://temanpeduli.com/detailprogram/' + this.program.seo, '_blank')
        linkWhatsapp.focus()
    }
    newTabClickedI() {
        let linkInstagram = window.open('https://www.instagram.com/temanpedulicom/', '_blank')
        linkInstagram.focus()
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }
    render() {
        const { match, match: { params } } = this.props
        const {
            events, image_link, go_back, go_donate,
            target, collected, percentage, final_day, donatur, total_donatur, donaturs, infoProgram,
            collapse_donatur, collapse_perkembangan, event_loading, event_avaibility, event_seo, program_seo
        } = this.state

        let seo = events.seo

        let url = this.props.location.pathname.split('/')
        let pls = null

        if (url[3]) {
            pls = seo + "/" + url[3]
        } else {
            pls = seo
        }

        let today = Date.now();
        let day_remaining = this.dateDiff(today, events.close_registration);

        let event_end = this.dateDiff(today, events.event_end);

        let registration_available = "";
        if (day_remaining < 0) {
            registration_available = <button style={{ textAlign: 'center', width: '50%', cursor: 'pointer' }} className="button-secondary bg-highlight button-m button-full button-round-medium shadow-small">REGISTRASI SEKARANG</button>
        } else {
            registration_available = <Link to={'/event/registrasi/' + pls} style={{ textAlign: 'center', width: '50%' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">REGISTRASI SEKARANG</Link>
        }

        // if (this.props.match.url === `/detail/${program.seo}`)
        //     window.location.href = `/detailprogram/${program.seo}`

        if (go_back)
            return <Redirect exact to='/event' />
        // return <Redirect exact to='/home' />
        else if (go_donate)
            return <Redirect exact to={`/donateform/${pls}`} />

        return (
            <div className="page-content">
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <Link to={'/event'} >
                            <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                            {String(events.judul).substring(0, 32)}...
                        </Link>
                    </div>
                </div>

                <Header back_url="/event" />

                <br></br>

                <div className="bg-donasi" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <Link to={'/detailprogram/' + program_seo} style={{ textAlign: 'center', width: '50%', marginRight: '5px' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">LIHAT PROGRAM</Link>
                    {registration_available}
                </div>

                <div className="content-boxed" id="programs_scroller" style={{ marginTop: 0 }}>
                    <div className="content">
                        <LoadingIndicator />
                        {event_loading == false ?
                            <>
                                <img className="caption-image owl-lazy" style={{ borderRadius: 13 + "px" }} src={image_link} /><br />
                                {event_end < 0 ?
                                    <center>
                                        <h6 style={{ color: 'red' }}>Event Sudah Berakhir</h6>
                                    </center>
                                    :
                                    <center>
                                        {day_remaining < 0 ?
                                            <center>
                                                <h6>Event Sedang Berlangsung</h6>
                                            </center>
                                            :
                                            <center>
                                                <h6>Pendaftaran Masih Tersedia</h6>
                                            </center>
                                        }
                                    </center>
                                }
                                <h3 style={{ lineHeight: 1.2 + 'em' }}>{events.judul}</h3>
                                <div className="float-left">
                                    <p>Pendaftaran Terakhir</p>
                                    <p className="txt-batas-waktu">Terkumpul Rp. {this.numberFormat(collected)}</p>
                                </div>
                                <div className="float-right">
                                    <p>{this.dateFormat(events.close_registration)}</p>
                                </div>
                                {/* {this.numberFormat(program.total)} dari Rp. {(program.dana_target) ? this.numberFormat(program.dana_target) : "∞"} */}
                                <br /><br /><br />
                                <div className="content-detail" style={{ width: 100 + '% !important' }}>
                                    {ReactHtmlParser(events.deskripsi)}
                                </div>

                                {events.type == 'cup_tournament' && events.phase !== 'registration' &&
                                    <Link to={'/event/bagan/' + events.seo} className="button bg-highlight button-m button-full button-round-medium shadow-small btn-chart">
                                        Lihat Bagan Event Ini
                                    </Link>
                                }


                                <div className="content-detail">
                                    <hr />
                                    <div style={{ textAlign: 'center' }}>
                                        <h5>Bagikan</h5>
                                        <p>Mari berbagi informasi yang Insya Allah bermanfaat kepada rekan dan saudara kita. Semoga menjadi amal soleh yang membawa keberkahan untuk anda. klik tombol share di bawah ini.</p>
                                    </div>

                                    {/* <div style={{display: 'flex', marginTop: 10+'px'}}>
                                    <div style={{marginRight: 10+'px'}}>
                                        <a href="#" onClick={() => this.newTabClickedW()}>
                                            <img src="/wa.png" style={{maxWidth: '50px'}}/>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="#" onClick={() => this.newTabClickedI()}>
                                            <img src="/ig.png" style={{maxWidth: '50px'}}/>
                                        </a>
                                    </div>
                                </div> */}
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <div className="float-right">
                                                        <a style={{ marginRight: 10 + 'px', cursor: 'pointer' }} onClick={() => this.newTabClickedW()}>
                                                            <img src="/wa2.png" style={{ maxWidth: '50px' }} />
                                                        </a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="float-left">
                                                        <a style={{ marginLeft: 10 + 'px', cursor: 'pointer' }} onClick={() => this.newTabClickedI()}>
                                                            <img src="/ig2.png" style={{ maxWidth: '50px' }} />
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <hr />
                                    <h5 style={{ cursor: 'pointer' }} onClick={() => this.setState({ collapse_donatur: !collapse_donatur })}>Tim Terdaftar ({events.event_program_participant.length})</h5>
                                    <br />
                                    <Collapse isOpened={collapse_donatur}>
                                        <div className="card-program">
                                            {events.event_program_participant.map((val, i) => {
                                                let image_link = Constant.GET_EVENT_PARTICIPANT_LOGO + val.id;
                                                return (
                                                    <div className="list-tim" key={i}>
                                                        <img className="logo-tim" src={image_link} />
                                                        <div className="info-tim">
                                                            {val.team_name}
                                                            <h5>Rp. {this.numberFormat(val.total_donation)}</h5>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Collapse>
                                </div>
                            </>
                            :
                            <>
                                <div>
                                    <center>
                                        {/* <label style={{ marginTop: '10px', color: '#3DADAA' }}>Sedang Mengunduh Data Program</label> */}
                                    </center>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailEvent