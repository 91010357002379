import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import Axios from 'axios';
import * as Constant from '../Constant'
import ReactHtmlParser from 'react-html-parser';

class RekeningDonasi extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
            data: {}
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
        this.getData()
    }
    componentWillUnmount() {
        
    }

    getData(){
        Axios.get(Constant.REKENING_DONASI)
        .then(response => {
            this.setState({
                data: response.data
            })
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        const { go_home, data } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div>         
            <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left back" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    {data.judul_page}
                </nav>
            </header>

            <section id="detprogram-box">
                <center><p className="zakat-title" style={{marginTop: 0+'px'}}>{data.judul_page}</p></center>
                <br/>
                { ReactHtmlParser(data.konten_page) }
            </section>
        </div>
        )
    }
}

export default RekeningDonasi