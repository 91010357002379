import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import './HitungZakat.css'

import Tab from '@material/react-tab'
import TabBar from '@material/react-tab-bar'
import '@material/react-tab-bar/dist/tab-bar.css';
import '@material/react-tab-scroller/dist/tab-scroller.css';
import '@material/react-tab/dist/tab.css';
import '@material/react-tab-indicator/dist/tab-indicator.css';

import ZakatPenghasilan from './Components/ZakatPenghasilan'
import ZakatHarta from './Components/ZakatHarta'
import ZakatFitrah from './Components/ZakatFitrah'

class HitungZakat extends Component {
    constructor() {
        super()
        this.state = {
            curr_index: 0,
            go_back: false
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";

    }
    componentWillUnmount() {
        
    }

    handleActiveIndexUpdate = (curr_index) => this.setState({curr_index});

    render() {
        const { curr_index, go_back } = this.state

        if (go_back)
            return <Redirect exact to="/zakat" />

        let selected_layout
        if (curr_index === 0)
            selected_layout = <ZakatPenghasilan />
        else if (curr_index === 1)
            selected_layout = <ZakatHarta />
        else if (curr_index === 2)
            selected_layout = <ZakatFitrah />

        return(
        <div>
            <header id="header">
                <nav className="left header-kembali">
                    <i id="ic-sidebar" className="fa fa-arrow-left back" onClick={() => this.setState({ go_back: true })}></i>
                    Hitung Zakat
                </nav>
            </header>

            <TabBar
                id="tab-menu"
                activeIndex={curr_index}
                handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            >
                <Tab style={{ width: "36%" }}>
                    <span className='mdc-tab__text-label'>
                        Zakat<br/>Penghasilan
                    </span>
                </Tab>
                <Tab style={{ width: "36%" }}>
                    <span className='mdc-tab__text-label'>
                        Zakat<br/>Harta (MAAL)
                    </span>
                </Tab>
                <Tab style={{ width: "28%" }}>
                    <span className='mdc-tab__text-label'>
                        Zakat<br/>Fitrah
                    </span>
                </Tab>
            </TabBar>

            {selected_layout}
        </div>
        )
    }
}

export default HitungZakat