import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'

class Zakat extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
            go_zakat: false,
            go_calculate: false
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        
    }

    render() {
        const { go_home, go_zakat, go_calculate } = this.state

        if (go_home)
            return <Redirect exact to='/home' />
        else if (go_zakat)
            return <Redirect exact to='/zakat/form-zakat' />
        else if (go_calculate)
            return <Redirect exact to='/zakat/kalkulator' />

        return(
        <div>         
            <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left back" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Zakat
                </nav>
            </header>

            <section id="detprogram-box">
                <center><p className="zakat-title">Ayo Tunaikan Zakat Sekarang</p></center>
                <br />
            
                <p className="txt-zakat">
                    Saatnya Bayar Zakat. Bersihkan harta anda dengan zakat di Teman Peduli. Insha Allah Mudah, berkah dan amanah.
                </p>
                <br/>

                <a 
                    className="btn-dns" 
                    style={{padding: "10px"}} 
                    onClick={() => this.setState({ go_zakat: true })}
                >
                    <p>Tunaikan Zakat</p>
                </a>
                <br/><br/>

                <a 
                    className="btn-dns" 
                    style={{padding: "10px"}} 
                    onClick={() => this.setState({ go_calculate: true })}
                >
                    <p>Hitung Zakat Anda</p>
                </a>
            </section>
        </div>
        )
    }
}

export default Zakat