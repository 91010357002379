import React, { Component } from 'react'
import {
    Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import { Home, FileText, PhoneCall, Menu, Cast, Box, User, LogIn, LogOut } from 'react-feather'
import * as Constant from '../Constant'
import Modal from 'react-modal';
import packageJson from '../../package.json';
const cookies = new Cookies();

const customStyles = {
    content: {
        top: '10px',
        bottom: '10px',
        right: '0px',
        width: '260px',
        overflow: 'scroll',
        border: '0px',
        borderRadius: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};
Modal.setAppElement('#root');


class Footer extends Component {
    constructor() {
        super()
        this.state = {
            id: '',
            gone: '',
            setIsOpen: false,
        }
    }

    componentDidMount() {
        // this.ifLogin()
        // console.log('hei', window.location.href.split("/"));

    }

    logOut() {
        cookies.remove('user_id');
        cookies.set('is_guest', true);
        window.location.href="/login"
    }

    render() {
        let endpoint = window.location.href.split("/")[3]
        let user_id;
        if (cookies.get('user_id') != null) {
            user_id = cookies.get('user_id')
        } else {
            user_id = null;
        }

        return (
            <>
                {/* FOOTER */}
                {/* <footer style={{margin: 10+'px', padding: 10+'px'}}>
                <center><p>Copyrights © 2020 All Rights Reserved by Teman Peduli.</p></center>
            </footer> */}<br /><br />
                <div id="footer-menu" className="footer-menu-5-icons footer-menu-style-3">
                    <div className="res menu-footer">
                        <div className="item-menu-footer">
                            {endpoint !== '' ?
                                <Link to={'/'} style={{ width: '100%' }}>
                                    <Home stroke={endpoint == '' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == '' ? "#3DADAA" : "#AAB2BD" }}>Beranda</label>
                                </Link>
                                :
                                <div className="item-menu-footer" style={{ width: '100%' }}>
                                    <Home stroke={endpoint == '' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == '' ? "#3DADAA" : "#AAB2BD" }}>Beranda</label>
                                </div>
                            }

                        </div>
                        <div className="item-menu-footer">
                            {endpoint !== "programs" ?
                                <Link to={'/programs'} style={{ width: '100%' }}>
                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                    <FileText stroke={endpoint == 'programs' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'programs' ? "#3DADAA" : "#AAB2BD" }}>Program</label>
                                </Link>
                                :
                                <div className="item-menu-footer" style={{ width: '100%' }}>
                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                    <FileText stroke={endpoint == 'programs' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'programs' ? "#3DADAA" : "#AAB2BD" }}>Program</label>
                                </div>
                            }
                        </div>
                        <div className="item-menu-footer">
                            {endpoint !== "event" ?
                                <Link to={'/event'} style={{ width: '100%' }}>
                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                    <Cast stroke={endpoint == 'event' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'event' ? "#3DADAA" : "#AAB2BD" }}>Event</label>
                                </Link>
                                :
                                <div className="item-menu-footer" style={{ width: '100%' }}>
                                    {/* Jika route tidak sama dengan endpoint, maka user dapat klik tombol ini untuk pindah halaman */}
                                    <Cast stroke={endpoint == 'event' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'event' ? "#3DADAA" : "#AAB2BD" }}>Event</label>
                                </div>
                            }
                        </div>
                        <div className="item-menu-footer">
                            {endpoint !== "merchandise" ?
                                <Link to="/merchandise" style={{ width: '100%' }}>
                                    <Box stroke={endpoint == 'merchandise' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'merchandise' ? "#3DADAA" : "#AAB2BD" }}>Merch</label>
                                </Link>
                                :
                                <div className="item-menu-footer" style={{ width: '100%' }}>
                                    <Box stroke={endpoint == 'merchandise' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'merchandise' ? "#3DADAA" : "#AAB2BD" }}>Merch</label>
                                </div>
                            }
                        </div>
                        <div className="item-menu-footer" style={{ cursor: 'pointer' }}>
                            <div className="item-menu-footer" style={{ width: '100%' }} onClick={() => this.setState({ setIsOpen: true })}>
                                <Menu stroke={'#AAB2BD'} size={30} />
                                <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: "#AAB2BD" }}>Menu</label>
                            </div>
                        </div>
                        {/*
                        <div className="item-menu-footer">
                            {endpoint !== "hubungi" ?
                                <Link to="/hubungi" style={{ width: '100%' }}>
                                    <PhoneCall stroke={endpoint == 'hubungi' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'hubungi' ? "#3DADAA" : "#AAB2BD" }}>Kontak</label>
                                </Link>
                                :
                                <div className="item-menu-footer" style={{ width: '100%' }}>
                                    <PhoneCall stroke={endpoint == 'hubungi' ? "#3DADAA" : '#AAB2BD'} size={30} />
                                    <label style={{ marginTop: '5px', fontSize: '12px', fontWeight: 'bold', color: endpoint == 'hubungi' ? "#3DADAA" : "#AAB2BD" }}>Kontak</label>
                                </div>
                            }
                        </div>
                        */}

                    </div>

                </div>



                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={() => this.setState({ setIsOpen: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    className="menu menu-box-right round-medium res"
                    closeTimeoutMS={300}>
                    <div className="res">
                        <div className="filter">
                            <div>
                                <h3 style={{ marginBottom: 30 + 'px' }}>Menu</h3>
                                <div>
                                    {user_id != null &&
                                        <Link to="/profile" style={{ width: '100%' }}>
                                            <div className="item-menu"><User stroke={'#AAB2BD'} size={25} className="ic-menu" />Profile</div>
                                        </Link>
                                    }
                                    <Link to="/hubungi" style={{ width: '100%' }}>
                                        <div className="item-menu"><PhoneCall stroke={'#AAB2BD'} size={25} className="ic-menu" />Hubungi Kami</div>
                                    </Link>
                                    {user_id == null &&
                                        <Link to="/login" style={{ width: '100%' }}>
                                            <div className="item-menu"><LogIn stroke={'#AAB2BD'} size={25} className="ic-menu" />Login</div>
                                        </Link>
                                    }
                                    {user_id != null &&
                                        <div className="item-menu"><LogOut stroke={'#AAB2BD'} size={25} className="ic-menu" onClick={() => this.logOut()} />Log Out</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <p style={{ position: 'absolute', bottom: '10px', marginBottom: '5px', width: '100%', textAlign: 'center' }}>Build Version v{packageJson.buildDate}</p>
                </Modal>

            </>
        )
    }
}


export default Footer
