import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';

class Mitra extends Component {
  render() {
    return (
      <>
        <div className="content bottom-10 top-30">
          <h5 className="float-left font-600">Mitra Perusahaan</h5>
          <div className="clear"></div>
        </div>
        <OwlCarousel items={2}
            loop
            autoplay
            autoplaySpeed={5}
            dots={false}
            stagePadding={30}
            lazyLoad={true}
            margin={10}
            animateIn="animate-in"
            animateOut="animate-out">
            <div className="item bg-theme round-small shadow-small center-text bottom-30">
                <img src="/tp/1.jpg" />
            </div>
            <div className="item bg-theme round-small shadow-small center-text bottom-30">
                <img src="/tp/3.jpg" />
            </div>
            <div className="item bg-theme round-small shadow-small center-text bottom-30">
                <img src="/tp/4.jpg" />
            </div>
            <div className="item bg-theme round-small shadow-small center-text bottom-30">
                <img src="/tp/5.jpg" />
            </div>
        </OwlCarousel>
      </>
    )
  };
}

export default Mitra;
