import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import * as Constant from '../Constant'

class Banner extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <OwlCarousel items={1}
                    className="single-slider slider-full bottom-10"
                    loop
                    autoplay
                    autoplaySpeed={20}
                    dots={true}
                    stagePadding={30}
                    lazyLoad={true}
                    margin={10}
                    animateIn="animate-in"
                    animateOut="animate-out">
                    {this.props.slideData.map((val, i) => {
                        return(
                            <Link to={val.link} key={i}>
                                <div className="caption round-medium shadow-large bottom-20">
                                    <img className="caption-image owl-lazy" data-src={Constant.BASE_URL_WEB+'/admin/assets/media/slider/245/'+val.gambar} />
                                </div>
                            </Link>
                        )
                    })}
                </OwlCarousel>
            </>
        )
    };
}

export default Banner;