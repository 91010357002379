import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import { Input } from '../Components/FormComponents';
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie';
import Header from '../Components/Header';

const cookies = new Cookies();

class Daftar extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            email: '',
            nama: '',
            no_telp: '',
            password: '',
            password_confirm: '',
            bio: '',
            alamat: '',

            go_back: false,
            go_home: false
        }
    }

    componentDidMount() {

    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    send() {
        const {
            username, email, nama, no_telp, password, password_confirm, bio, alamat
        } = this.state

        if (username == null || username == '') {
            alert('Username Tidak Boleh Kosong')
        } else if (email == null || email == '') {
            alert('Email Tidak Boleh Kosong')
        } else if (nama == null || nama == '') {
            alert('Nama Tidak Boleh Kosong')
        } else if (no_telp == null || no_telp == '') {
            alert('Nomor Telepon Tidak Boleh Kosong')
        } else if (password == null || password == '') {
            alert('Password Tidak Boleh Kosong')
        } else if (password_confirm == null || password_confirm == '') {
            alert('Ketik Ulang Password Tidak Boleh Kosong')
        } else if (password != password_confirm) {
            alert('Password Tidak Sama')
        } else if (bio == null || bio == '') {
            alert('Bio Tidak Boleh Kosong')
        } else if (alamat == null || alamat == '') {
            alert('Alamat Tidak Boleh Kosong')
        } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            const parameter = {
                "username": username,
                "email": email,
                "name": nama,
                "no_telp": no_telp,
                "password": password,
                "bio": bio,
                "alamat": alamat,
                "key_yayasan": Constant.KEY_YAYASAN
            }

            axios.post(Constant.REGISTER, parameter).
                then(response => {
                    if (response.data.status == 400) {
                        alert(response.data.error)
                    } else {
                        alert('Email Terkirim, mohon cek email untuk reset password !')
                        this.setState({ go_home: true })
                    }
                }).catch(error => {
                    console.log(error)
                })
        } else {
            alert('Email Tidak Valid')
        }
    }
    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }
    render() {
        const {
            username, email, nama, no_telp, password, password_confirm, bio, alamat, go_back, go_home
        } = this.state

        if (go_back)
            return <Redirect exact to="/login" />
        else if (go_home)
            return <Redirect exact to="/home" />

        return (
            <div className="page-content">
                <Header />
                <div className="content-boxed">
                    <section id="donasi-box">
                        <center><label style={{ fontWeight: 'bold', fontSize: '22px', color: '#3DADAA' }}>Daftar</label></center>
                        <Input type="text" name="username" value={username} placeholder="Masukkan Username" callback={this.callbackInput} />
                        <Input type="email" name="email" value={email} placeholder="Masukkan Email" callback={this.callbackInput} />
                        <Input type="text" name="nama" value={nama} placeholder="Masukkan Nama" callback={this.callbackInput} />
                        <Input type="number" name="no_telp" value={no_telp} placeholder="Masukkan No Telp" callback={this.callbackInput} />
                        <Input type="password" name="password" value={password} placeholder="Masukkan Password" callback={this.callbackInput} />
                        <Input type="password" name="password_confirm" value={password_confirm} placeholder="Ketik Ulang Password" callback={this.callbackInput} />
                        <Input type="text" name="bio" value={bio} placeholder="Bio" callback={this.callbackInput} />
                        <Input type="text" name="alamat" value={alamat} placeholder="Alamat" callback={this.callbackInput} />
                        <a className="donasi" onClick={() => this.send()} style={{ cursor: 'pointer' }}>DAFTAR</a>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <Link to="/login">
                                Sudah Punya Akun? Masuk Disini
                            </Link>
                        </div>
                   </section>
                </div>
            </div>
        )
    }
}

export default Daftar