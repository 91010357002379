import React, { Component } from 'react'
import {
    Redirect, Link
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import axios from 'axios'
import Header from '../Components/Header'
import { Collapse } from 'react-collapse';

// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import OwlCarousel from 'react-owl-carousel';
import Loader from 'react-loader-spinner';

// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }}>
            <Loader type="Oval" color={'#3DADAA'} height="80" width="80" />
        </div>
    )
}


class Bagan extends Component {
    constructor() {
        super()
        this.state = {
            events: [],
            image_link: '',
            match_event: [],

            target: 0,
            collected: 0,
            percentage: 0,
            final_day: Date.now(),
            donatur: 0,

            total_donatur: 0,

            page: 1,
            last_page: 1,
            loading: true,

            go_back: false,
            go_donate: false,
            event_loading: true,

            collapse_perkembangan: false,
            collapse_donatur: false,

            event_avaibility: false,
            event_seo: null,
            program_seo: null,
            list_batch: [],
            current_batch: ''
        }

        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        // window.onpopstate = (event) => {
        //     console.log('haiiii', event);
        // }

        this.checkEvent()
        this.getEventDetail()

        document.addEventListener('scroll', this.trackScrolling)

    }

    componentWillMount() {
        this.getMatch()
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight &&
            this.state.page != this.state.last_page) {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({
                page: next,
                loading: true
            })
            // this.getDonaturs()
        }
    }

    checkEvent() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.CHECK_EVENT_AVAIBILITY + params.seoKey)
                .then(response => {
                    if (response.data.data !== null) {
                        this.setState({
                            event_avaibility: true,
                            event_seo: response.data.data.seo
                        })
                    }
                })
        );
    }

    changeBatch(batch) {
        this.getMatch(batch)
    }

    getMatch(batch) {
        if (batch == null) {
            batch = 1
        }
        let { match: { params } } = this.props
        let { current_batch } = this.state
        axios.get(Constant.GET_EVENT_MATCH + params.seoKey + '?batch=' + batch).then(
            response => {
                let data = response.data;
                this.setState({
                    match_event: data.data,
                    list_batch: data.list_batch,
                    current_batch: data.current_batch
                })
            }
        )
    }

    getEventDetail() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.GET_EVENT_PROGRAMS_DETAIL + params.seoKey)
                .then(response => {
                    let data = response.data

                    let target = data.program.program.dana_target
                    let collected = (data.program.program.donatur[0]) ? data.program.program.donatur[0].total : 0

                    let percentage = 0

                    if (data.program.program.dana_target != null || data.program.program.dana_target >= 0) {
                        percentage = (collected / target) * 100
                    } else {
                        percentage = 100
                    }

                    this.setState({
                        events: data.data,
                        image_link: Constant.GET_EVENT_PROGRAMS_IMAGE + data.data.event_program_id,
                        program_seo: data.program.seo,

                        target: target,
                        collected: collected,
                        percentage: percentage,

                        event_loading: false,
                        //final_day: tanggal_akhir,
                    });

                    if (response.data.program.program.donatur[0]) {
                        this.setState({
                            donatur: response.data.program.program.donatur[0].donatur,
                        })
                    } // console.log('tgl', final_day)
                    //this.getDonaturs()
                    //this.getInfoProgram()
                    //this.countDonatur()
                })
                // Catch any error here
                .catch(error => {
                    console.log(error)
                })
        )

    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret',
                'April', 'Mei', 'Juni', 'Juli',
                'Agustus', 'September', 'Oktober',
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", "
                + new_date.getDate() + " "
                + months[new_date.getMonth()] + " "
                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }
    newTabClickedW() {
        let linkWhatsapp = window.open('https://wa.me/?text=https://temanpeduli.com/detailprogram/' + this.program.seo, '_blank')
        linkWhatsapp.focus()
    }
    newTabClickedI() {
        let linkInstagram = window.open('https://www.instagram.com/temanpedulicom/', '_blank')
        linkInstagram.focus()
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }
    render() {
        const { match, match: { params } } = this.props
        const {
            events, image_link, go_back, go_donate,
            target, collected, percentage, final_day, donatur, total_donatur, donaturs, infoProgram,
            collapse_donatur, collapse_perkembangan, event_loading, event_avaibility, event_seo, program_seo,
            match_event, list_batch, current_batch
        } = this.state

        let seo = events.seo

        let url = this.props.location.pathname.split('/')
        let pls = null

        if (url[3]) {
            pls = seo + "/" + url[3]
        } else {
            pls = seo
        }

        let today = Date.now();
        let day_remaining = this.dateDiff(today, events.close_registration);

        let event_end = this.dateDiff(today, events.event_end);

        let registration_available = "";
        if (day_remaining < 0) {
            registration_available = <button style={{ textAlign: 'center', width: '50%', cursor: 'pointer' }} className="button-secondary bg-highlight button-m button-full button-round-medium shadow-small">REGISTRASI SEKARANG</button>
        } else {
            registration_available = <Link to={'/event/registrasi/' + pls} style={{ textAlign: 'center', width: '50%' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">REGISTRASI SEKARANG</Link>
        }

        // if (this.props.match.url === `/detail/${program.seo}`)
        //     window.location.href = `/detailprogram/${program.seo}`

        if (go_back)
            return <Redirect exact to='/event' />
        // return <Redirect exact to='/home' />
        else if (go_donate)
            return <Redirect exact to={`/donateform/${pls}`} />

        return (
            <div className="page-content">
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <Link to={'/event'} >
                            <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                            {String(events.judul).substring(0, 32)}...
                        </Link>
                    </div>
                </div>

                <Header back_url="/event" />

                <br></br>



                <div className="content-boxed" id="programs_scroller" style={{ marginTop: 0 }}>
                    <p className="title-bagan">Bagan Pertandingan</p>
                    <p className="subtitle-bagan">Batch #{current_batch}</p>
                    <OwlCarousel items={1}
                        loop
                        dots={true}
                        stagePadding={30}
                        lazyLoad={true}
                        margin={10}
                        animateIn="animate-in"
                        animateOut="animate-out">
                        {match_event.map((val, i) => {
                            let image_1 = Constant.GET_EVENT_PARTICIPANT_LOGO + val.participant_1;
                            let image_2 = Constant.GET_EVENT_PARTICIPANT_LOGO + val.participant_2;
                            let par_1 = "";
                            let par_2 = ""
                            if(val.winner == val.participant_1) {
                                par_1 = val.name_1;
                                par_2 = <s>{val.name_2}</s>;
                            } else if(val.winner == val.participant_2) {
                                par_2 = val.name_2;
                                par_1 = <s>{val.name_1}</s>;
                            } else {
                                par_1 = val.name_1;
                                par_2 = val.name_2
                            }
                            return (
                                <div className="card-match bg-theme round-small shadow-small">
                                    <p className="no-match">#{val.match_order}</p>
                                    <p className="match-note">{val.match_note}</p>
                                    <p className="date-match">{val.match_time}</p>
                                    <div className="container-match">
                                        <div className="tim">
                                            <div className="img-tim" style={{ backgroundImage: 'url(' + image_1 + ')' }}></div>
                                            <p className="nama-tim">{par_1}</p>
                                        </div>
                                        <div className="versus">VS</div>
                                        <div className="tim">
                                            <div className="img-tim" style={{ backgroundImage: 'url(' + image_2 + ')' }}></div>
                                            <p className="nama-tim">{par_2}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </OwlCarousel>
                </div>

                <div className="content-boxed" style={{ padding: '12px', display: 'flex', flexDirection: 'column' }}>
                    {list_batch.map((val, i) => {
                        return (
                            <button key={i} style={{ textAlign: 'center', width: '100%', cursor: 'pointer' }} className="button bg-highlight button-m button-full button-round-medium shadow-small" onClick={() => this.changeBatch(val.batch_order)}>
                                LIHAT BATCH {val.batch_order}
                            </button>
                        )
                    })}
                </div>
            </div>
        )
    }
}
export default Bagan