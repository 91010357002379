import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import { MetodePembayaran, Input, Nominal } from '../../Components/FormComponents';
import * as Constant from '../../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';
import Header from '../../Components/Header';

const cookies = new Cookies();

class Donate extends Component {
    constructor() {
        super()
        this.state = {
            user_id: null,
            program: {},
            donatian: 0,
            full_name: '',
            phone_number: '',
            email: '',

            phone: '',
            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            alfamart_code: 'ALFAMARTCODEHERE!!!',

            go_back: false,
            go_paid: false,

            go_payment: false,
            checkout_url: '',
            go_via_checkout: false,

            donation_type: [],
            nominals: [],
            metode_pembayaran: 'Pilih Metode Pembayaran',
            btn_donasi: 'Donasi',
            btn_disabled: false,
            setIsOpen: false,

            //PaymentMethod
            payment_method: null,
            vendor: null,
            selected_method: null,
            selected_vendor: null,
            no_rekening: '',
        }
        this.sendTransaction = this.sendTransaction.bind(this);
        this.sendDonation = this.sendDonation.bind(this);
    }

    componentDidMount() {
        this.getProgramDetail()
        this.setUserIfLogin()
        //this.getNominal()
        if (cookies.get('customerBio')) {
            this.setState({
                user_id: cookies.get('customerBio').user_id,
                full_name: cookies.get('customerBio').full_name,
                phone_number: cookies.get('customerBio').phone_number,
                email: cookies.get('customerBio').email,
            })
        }
        if (cookies.get('selected_method')) {
            this.getPaymentMethod()
        } else {
            document.getElementById('logo_payment').hidden = true
            document.getElementById('label_phone').hidden = true
            document.getElementById('card_number').hidden = true
            document.getElementById('exp_month').hidden = true
            document.getElementById('exp_year').hidden = true
            document.getElementById('cvn').hidden = true
        }

        if (cookies.get('user_id') !== null) {
            this.setState({ user_id: cookies.get('user_id') })
        }

    }

    getProgramDetail() {
        let { match: { params } } = this.props
        axios.get(Constant.GET_PROGRAM_DETAIL + params.seoKey)
            .then(response => {
                this.setState({
                    program: response.data.data,
                    image_link: Constant.GET_PROGRAMS_IMAGE + response.data.data.id_program
                });
                this.getDonationNominals()
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }

    getDonationNominals() {
        const { program } = this.state
        let id_program = program.id_program
        axios.get(Constant.GET_DONATION_NOMINAL + id_program)
            .then(response => {
                console.log(response.data.data)
                if (response.data.data) {
                    this.setState({
                        donation_type: response.data.data
                    })
                } else {
                    this.getNominal()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    setUserIfLogin() {
        if (cookies.get('user_id') != null) {
            axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(response => {
                this.setState({
                    user_id: response.data.id,
                    full_name: response.data.name,
                    phone_number: response.data.no_telp,
                    email: response.data.email
                });

                document.getElementById('full_name').readOnly = true
                // document.getElementById('phone_number').readOnly = true
                document.getElementById('email').readOnly = true
                document.getElementById('btn_for_login').hidden = true

            }).catch(error => {
                console.log(error)
            })
        }
    }
    getNominal() {
        axios.get(Constant.GET_NOMINAL_BUTTON)
            .then(response => {
                console.log(response.data.data)
                this.setState({ nominals: response.data.data })
            }).catch(error => {
                console.log(error)
            })
    }

    getPaymentMethod() {
        let selected_method = cookies.get('selected_method')
        if (selected_method != null || selected_method != '') {
            cookies.remove('linkBefore')

            if (cookies.get('customerBio')) {
                this.setState({
                    donation: cookies.get('customerBio').donation,
                    user_id: cookies.get('customerBio').user_id,
                    full_name: cookies.get('customerBio').full_name,
                    phone_number: cookies.get('customerBio').phone_number,
                    email: cookies.get('customerBio').email,

                    payment_method: cookies.get('selected_method'),
                    vendor: cookies.get('vendor')
                })
            }

            // console.log(cookies.get('vendor').payment_name)

            if (cookies.get('vendor').payment_name == "OVO" || cookies.get('vendor').payment_name == "LINKAJA") {
                document.getElementById('label_phone').hidden = false
                document.getElementById('phone').hidden = false
            } else {
                document.getElementById('label_phone').hidden = true
                document.getElementById('phone').hidden = true
            }

            if (cookies.get('vendor').payment_name == "Visa, Mastercard, JCB") {
                document.getElementById('card_number').hidden = false
                document.getElementById('exp_month').hidden = false
                document.getElementById('exp_year').hidden = false
                document.getElementById('cvn').hidden = false
            } else {
                document.getElementById('card_number').hidden = true
                document.getElementById('exp_month').hidden = true
                document.getElementById('exp_year').hidden = true
                document.getElementById('cvn').hidden = true
            }
        }
    }
    onSuggestButtonClicked(value) {
        this.setState({ donation: parseInt(value.replace(/\./g, '')) })
    }
    onAmountChanged(e) {
        const { name, value } = e.target
        // console.log(value)
        this.setState({ [name]: parseInt(value.split('.').join('').split(',').join('')) })
    }
    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }
    goToPayment() {
        const {
            donation, user_id, full_name, phone_number, email
        } = this.state

        let url = this.props.location.pathname
        let array = {
            'donation': donation,
            'user_id': user_id,
            'full_name': full_name,
            'phone_number': phone_number,
            'email': email
        }

        cookies.set('linkBefore', url);
        cookies.set('customerBio', array);
        this.setState({ go_payment: true })
    }

    sendDonation(event) {
        if (!event.detail || event.detail == 1) {
            document.getElementById("btnDonasi").disabled = true;
            this.doDonation()
        }
    }

    doDonation() {
        console.log('cek transaksi')
        const {
            user_id, program, donation, vendor, payment_method,
            full_name, phone_number, email, phone, selected_vendor,
            donation_type
        } = this.state

        let url = this.props.location.pathname.split('/')

        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );

        if (selected_vendor !== null) {
            if (selected_vendor.payment_type != "e_wallet") {
                if (donation_type !== null && donation < donation_type.value) {
                    alert('Donasi Minimal Rp.' + donation_type.value)
                    document.getElementById("btnDonasi").disabled = false;
                } else if (!donation || donation < 10000) {
                    alert('Donasi Untuk Metode Pembayaran Ini Minimal Rp.10.000')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (full_name == "" || full_name == null) {
                    alert('Nama Lengkap Tidak Boleh Kosong')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (phone_number == "" || phone_number == null) {
                    alert('Nomor Telepon Tidak Boleh Kosong')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (email == "" || email == null) {
                    alert('Email Tidak Boleh Kosong')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (payment_method == 0) {
                    alert('Pilih Metode Pembayaran')
                    document.getElementById("btnDonasi").disabled = false;
                } else {
                    if (pattern.test(email)) {
                        let vendor_name = selected_vendor.payment_name
                        // console.log(vendor_name)
                        if (vendor_name == "OVO") {
                            if (phone == null || phone == "") {
                                alert('Harap Isi Nomor Telepon E-Wallet Anda')
                                document.getElementById("btnDonasi").disabled = false;
                            } else {
                                document.getElementById("ovo_popup").style.display = "block"
                                this.sendTransaction()
                            }
                        } else {
                            this.sendTransaction()
                        }
                    } else {
                        alert('Email Tidak Valid')
                        document.getElementById("btnDonasi").disabled = false;
                    }
                }
                
            } else {
                if (donation_type !== null && donation < donation_type.value) {
                    alert('Donasi Minimal Rp.' + donation_type.value)
                    document.getElementById("btnDonasi").disabled = false;
                } else if (!donation || donation < 1) {
                    alert('Donasi Minimal Rp.1')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (full_name == "" || full_name == null) {
                    alert('Nama Lengkap Tidak Boleh Kosong')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (phone_number == "" || phone_number == null) {
                    alert('Nomor Telepon Tidak Boleh Kosong')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (email == "" || email == null) {
                    alert('Email Tidak Boleh Kosong')
                    document.getElementById("btnDonasi").disabled = false;
                } else if (payment_method == 0) {
                    alert('Pilih Metode Pembayaran')
                    document.getElementById("btnDonasi").disabled = false;
                } else {
                    if (pattern.test(email)) {
                        let vendor_name = selected_vendor.payment_name
                        // console.log(vendor_name)
                        if (vendor_name == "OVO") {
                            if (phone == null || phone == "") {
                                alert('Harap Isi Nomor Telepon E-Wallet Anda')
                        document.getElementById("btnDonasi").disabled = false;
                            } else {
                                document.getElementById("ovo_popup").style.display = "block"
                                this.sendTransaction()
                            }
                        } else {
                            this.sendTransaction()
                        }
                    } else {
                        alert('Email Tidak Valid')
                        document.getElementById("btnDonasi").disabled = false;
                    }
                } 
            }
        }
    }
    updateDeviceTypeTransaction(id) {
        let parameter = {
            id: id,
            transaksi_id: "P" + id,
            device: "PWA"
        }
        axios.post(Constant.UPDATE_DEVICE_AFTER_TRANSACTION, parameter, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                // console.log(response.data)
                this.setState({ go_paid: true })
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    sendTransaction() {
        const {
            user_id, program, donation, vendor, payment_method, phone,
            full_name, phone_number, email,
            card_number, exp_month, exp_year, cvn, selected_vendor,
            no_rekening
        } = this.state

        let nomor_hp = null
        if (selected_vendor.payment_name == 'OVO' || selected_vendor.payment_name == 'DANA') {
            nomor_hp = phone
        } else {
            nomor_hp = phone_number
        }
        let metode_pembayaran = null
        if (selected_vendor.payment_type == 'virtual_account') {
            metode_pembayaran = selected_vendor.midtrans_code
        } else if (selected_vendor.payment_name == "Alfamart") {
            metode_pembayaran = selected_vendor.payment_name.toUpperCase()
        } else {
            metode_pembayaran = selected_vendor.payment_name
        }

        let array = []
        if (selected_vendor.payment_type == 'credit_card') {
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }

        const parameter = {
            'key_yayasan': Constant.KEY_YAYASAN,
            'nominal': donation,
            'nama_lengkap': full_name,
            'email': email,
            'no_handphone': phone_number,
            'program_id': program.id_program,
            'vendor': selected_vendor.vendor,
            'payment_type': selected_vendor.payment_type,
            'no_rekening': no_rekening,
            'payment_method': metode_pembayaran,
            'device': 'PWA',
        }
        axios.post(Constant.STORE_XENDIT, parameter)
            .then(response => {
                console.log(response)
                if (response.data.snap_token != null) {
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    console.log(response)
                    window.snap.pay(response.data.snap_token, {
                        // Optional
                        onSuccess: function (result) {
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                        },
                        // Optional
                        onPending: function (result) {
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                        },
                        // Optional
                        onError: function (result) {
                            console.log("gagal")
                            document.getElementById("btnDonasi").disabled = false;
                        },
                        onClose: function (result) {
                            console.log(response.data)
                            let id = ''
                            if (selected_vendor.vendor == "midtrans") {
                                id = response.data.snap_token
                            }
                            const data_send = {
                                iDtoken: id,
                                status: 1,
                                key_yayasan: Constant.KEY_YAYASAN
                            }
                            axios.post(Constant.DELETE_SNAPTOKEN, data_send)
                                .then(response => {
                                    alert("Transaksi Dibatalkan")
                                    document.getElementById("btnDonasi").disabled = false;
                                })
                                .catch(error => {
                                    console.log(error)
                                    document.getElementById("btnDonasi").disabled = false;
                                })
                        }
                    })
                } else {
                    console.log(response)
                    let pisah_url = window.location.href.split('/')
                    window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                    if (response.data.data_ew) {
                        window.location.href = response.data.data_ew.checkout_url
                    }
                    if (response.data.data_ro) {
                        this.setState({
                            alfamart_code: response.data.data_ro.payment_code
                        })
                        console.log(this.state.alfamart_code)
                        document.getElementById("alfamart_popup").style.display = "block"
                        document.getElementById("btnDonasi").disabled = false;
                    }
                }
            })
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    callbackNominal = (nominal) => {
        this.setState({ donation: nominal });
    }

    callbackVendor = (callVendor, callMethod, callRekening) => {
        this.setState({ selected_method: callMethod, selected_vendor: callVendor, no_rekening: callRekening });
    }

    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }
    render() {
        const { match, match: { params } } = this.props
        const {
            program, image_link, donation, full_name, payment_method, vendor, phone,
            phone_number, email, go_back, go_paid, go_payment, donation_type,
            card_number, exp_month, exp_year, cvn, checkout_url, go_via_checkout, alfamart_code, nominals, user_id,
            btn_disabled
        } = this.state
        let readOnly = false;

        let foto = ''
        if (vendor) {
            foto = Constant.GET_PICT_VENDOR + vendor.id
        }

        if (go_back)
            return <Redirect exact to={`/detail/${params.seoKey}`} />
        else if (go_paid)
            return <Redirect exact to="/notif/notif-wait" />
        else if (go_payment)
            return <Redirect exact to="/payment_method" />

        if (user_id != null) {
            readOnly = true;
        }
        return (
            <div className="page-content">

                <Header back_url={'/detailprogram/' + program.seo} />
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <i id="ic-sidebar" className="fa fa-arrow-left" onClick={() => this.setState({ go_back: true })} style={{ padding: 20 + "px" }}></i>
                        {String(program.judul).substring(0, 32)}...
                    </div>
                </div>

                <div className="content-boxed" style={{ marginTop: 20 + 'px', marginBottom: 10 + 'px' }}>
                    <div className="content" id="program-list-content">
                        <div className="program-home-image" style={{ backgroundImage: `url(${image_link})` }}></div>
                        {/* <div className="program-home-image" style={{backgroundImage: `url(tp/banner.png)`}}></div> */}
                        <div className="program-desc">
                            <p style={{ marginBottom: 0 }}>Program yang di pilih :</p>
                            <b><p className="title-program">{program.judul}</p></b>
                        </div>
                    </div>
                </div>

                <div className="content-boxed">
                    <section id="donasi-box">
                        <Nominal label="Nominal Donasi (Rp)" donation_type={donation_type} nominal={nominals} callback={this.callbackNominal} />
                        <MetodePembayaran callback={this.callbackVendor} />
                        <div id="btn_for_login">
                            <br />
                            <center style={{ fontSize: 12 + 'px', color: '#8a8a8a' }}>
                                Silahkan isi data di bawah ini
                        </center>
                        </div>

                        <p className="label-form" id="label_phone">Nomor Telepon E-Wallet</p>
                        <input type="number" name="phone" id="phone" className="input-form" value={phone} onChange={(e) => this.handleInputChange(e)} style={{ display: 'none' }} />

                        {/* credit_card */}
                        {/* <p className="label-form" id="label_phone"></p> */}
                        <input type="number" name="card_number" placeholder="Card Number" id="card_number" className="input-form" value={card_number} onChange={(e) => this.handleInputChange(e)} />

                        {/* <p className="label-form" id="label_phone"></p> */}
                        <input type="number" name="exp_month" placeholder="Expired Month" id="exp_month" className="input-form" max="12" value={exp_month} onChange={(e) => this.handleInputChange(e)} />

                        {/* <p className="label-form" id="label_phone"></p> */}
                        <input type="number" name="exp_year" placeholder="Expired Year" id="exp_year" className="input-form" max="2100" value={exp_year} onChange={(e) => this.handleInputChange(e)} />

                        {/* <p className="label-form" id="label_phone"></p> */}
                        <input type="number" name="cvn" placeholder="CVN" id="cvn" className="input-form" value={cvn} onChange={(e) => this.handleInputChange(e)} />
                        {/* end credit_card */}
                        <Input type="text" name="full_name" value={full_name} placeholder="Nama Lengkap Donatur" callback={this.callbackInput} readOnly={readOnly} />
                        <Input type="number" name="phone_number" value={phone_number} placeholder="Nomor Ponsel atau Whatsapp" callback={this.callbackInput} readOnly={readOnly} />
                        <Input type="email" name="email" value={email} placeholder="Email" callback={this.callbackInput} readOnly={readOnly} />


                        <br />
                        <button id="btnDonasi" className="donasi" onClick={this.sendDonation} style={{ cursor: 'pointer', width: '100%' }}>Donasi</button>
                    </section>
                </div>

            </div>
        )
    }
}

export default Donate