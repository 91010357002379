import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../../Constant'
import axios from 'axios'

class DetailUpdate extends Component {
    constructor() {
        super()
        this.state = {
            update: {},
            image_link: '',

            target: 0,
            collected: 0,
            percentage: 0,
            final_day: Date.now(),
            donatur: 0,

            go_back: false,
            go_donate: false
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none"
        this.getUpdateDetail()
    }
    componentWillUnmount() {
        
    }

    getUpdateDetail() {
        let { match:{ params } } = this.props
        axios.get(Constant.GET_UPDATE_DETAIL + params.seoKey)
            .then(response => {
                console.log(response)
                let update = response.data.data
                this.setState({ 
                    update: update,
                    image_link: Constant.GET_UPDATES_IMAGE + update.id_posting,
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu'];
        let months = [
            'Januari', 'Februari', 'Maret', 
            'April', 'Mei', 'Juni', 'Juli', 
            'Agustus', 'September', 'Oktober', 
            'November', 'Desember'
        ]

        let new_date = new Date(date)
        let formatted_date = days[new_date.getDay()] + ", " 
                            + new_date.getDate() + " "
                            + months[new_date.getMonth()] + " "
                            + new_date.getFullYear()

        return formatted_date
    }

    render() {
        const { match, match:{ params } } = this.props
        const { 
            update, image_link, go_back, go_donate,
            target, collected, percentage, final_day, donatur
        } = this.state

        console.log(update)
        let linkFacebook = "https://www.facebook.com/sharer/sharer.php?u=https://mizanamanah.or.id/update/"+update.seo
        let linkTwitter = "http://twitter.com/intent/tweet?url="+update.judul+" Klik Link Berikut https://mizanamanah.or.id/update/"+update.seo
        let linkEmail = "mailto:?Subject=&Body="+update.judul+" Klik Link Berikut https://mizanamanah.or.id/update/"+update.seo
        let linkWhatsapp = "https://api.whatsapp.com/send?text="+update.judul+" Klik Link Berikut https://mizanamanah.or.id/update/"+update.seo

        if (go_back)
            return <Redirect exact to='/update' />

            console.log(update.judul)

        return(
            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i id="ic-sidebar" className="fa fa-arrow-left back" onClick={() => this.setState({ go_back: true })}></i>
                        {String(update.judul).substring(0, 32)}...
                    </nav>
                </header>

                <section id="detprogram-box">
                    <div className="banner-program" style={{backgroundImage: `url(${image_link})`}}>
                    </div>
                    <p className="title-card-program txt-18">{update.judul}</p>
                    
                    <div className="content-detail">
                        { ReactHtmlParser(update.content) }
                    </div>

                    <div className="content-detail">
                                <hr/>
                                Mari berbagi informasi yang insha Allah bermanfaat kepada rekan dan saudara kita. Semoga menjadi amal soleh yang membawa keberkahan untuk anda. klik tombol share di bawah ini.
                                <table style={{marginBottom: '0px'}}>
                                    <tr>
                                        <td width="25%" align="center">
                                            <a href={linkFacebook}>
                                                <img src="/facebook.png" style={{maxWidth: '50px'}}/>
                                            </a>
                                        </td>
                                        <td width="25%" align="center">
                                            <a href={linkTwitter}>
                                                <img src="/twitter.png" style={{maxWidth: '50px'}}/>
                                            </a>
                                        </td>
                                        <td width="25%" align="center">
                                            <a href={linkEmail}>
                                                <img src="/gmail.png" style={{maxWidth: '50px'}}/>
                                            </a>
                                        </td>
                                        <td width="25%" align="center">
                                            <a href={linkWhatsapp}>
                                                <img src="/whatsapp.png" style={{maxWidth: '50px'}}/>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                </section>
            </div>
        )
    }
}

export default DetailUpdate