import React, { Component } from 'react'
import {
    Redirect, Link
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../../Constant'
import axios from 'axios'
import Header from '../../Components/Header'
import { Collapse } from 'react-collapse';
import Modal from 'react-modal';

// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;

Modal.setAppElement('#root');

const customStyles = {
    content: {
        position: 'relative',
        top: '30px',
        right: '0px',
        width: '420px',
        zIndex: 10
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
        }}>
            <Loader type="Oval" color={'#3DADAA'} height="80" width="80" />
        </div>
    )
}


class DetailProgram extends Component {
    constructor() {
        super()
        this.state = {
            program: {},
            image_link: '',

            target: 0,
            collected: 0,
            percentage: 0,
            final_day: Date.now(),
            donatur: 0,

            total_donatur: 0,
            donaturs: [],
            infoProgram: [],

            page: 1,
            last_page: 1,
            loading: true,

            go_back: false,
            go_donate: false,
            program_loading: true,

            collapse_perkembangan: false,
            collapse_donatur: false,

            event_avaibility: false,
            event_seo: null,

            setIsOpen: false,
            events: []
        }

        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        // window.onpopstate = (event) => {
        //     console.log('haiiii', event);
        // }

        this.checkEvent()
        this.getProgramDetail()
        this.getEventList()

        document.addEventListener('scroll', this.trackScrolling)

    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }


    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight &&
            this.state.page != this.state.last_page) {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({
                page: next,
                loading: true
            })
            this.getDonaturs()
        }
    }

    checkEvent() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.CHECK_EVENT_AVAIBILITY + params.seoKey)
                .then(response => {
                    if (response.data.data !== null) {
                        this.setState({
                            event_avaibility: true,
                            event_seo: response.data.data.seo
                        })
                    }
                })
        );
    }

    getEventList() {
        let { match: { params } } = this.props

        trackPromise(
            axios.get(Constant.GET_EVENT_PROGRAMS + `?yayasan=` + Constant.KEY_YAYASAN + `&program=` + params.seoKey)
                .then(response => {
                    console.log(response.data);
                    this.setState({
                        events: response.data.data.data,
                    })
                })
        )
    }

    getProgramDetail() {
        let { match: { params } } = this.props
        trackPromise(
            axios.get(Constant.GET_PROGRAM_DETAIL + params.seoKey)
                .then(response => {
                    let program = response.data.data
                    this.program = program

                    let target = program.program.dana_target
                    let collected = (program.program.donatur[0]) ? program.program.donatur[0].total : 0

                    let percentage = 0

                    if (program.program.dana_target != null || program.program.dana_target >= 0) {
                        percentage = (collected / target) * 100
                    } else {
                        percentage = 100
                    }

                    let tanggal_akhir = null

                    if (!response.data.data.program.tanggal) {
                        tanggal_akhir = null
                    } else {
                        tanggal_akhir = new Date(response.data.data.program.tanggal)
                    }

                    this.setState({
                        program: program,
                        image_link: Constant.GET_PROGRAMS_IMAGE + response.data.data.id_program,

                        target: target,
                        collected: collected,
                        percentage: percentage,

                        final_day: tanggal_akhir,
                    });

                    if (response.data.data.program.donatur[0]) {
                        this.setState({
                            donatur: response.data.data.program.donatur[0].donatur,
                        })
                    }
                    // console.log('tgl', final_day)
                    this.getDonaturs()
                    this.getInfoProgram()
                    this.countDonatur()
                })
                // Catch any error here
                .catch(error => {
                    console.log(error)
                })
        )

    }
    getInfoProgram() {
        const { program, page } = this.state;
        let query_url = `?page=${page}`

        axios.get(Constant.GET_INFO_PROGRAM + program.id_program + query_url)
            .then(response => {
                if (page == 1) {
                    this.setState({
                        infoProgram: response.data.data
                    });
                } else {
                    let newInfo = this.state.infoProgram.concat(response.data.data);
                    this.setState({
                        infoProgram: newInfo
                    })
                }
            })

    }
    getDonaturs() {
        const {
            program, page
        } = this.state

        let query_url = `?page=${page}`

        axios.get(Constant.GET_DONATURS + program.id + query_url)
            .then(response => {
                if (page == 1) {
                    this.setState({
                        donaturs: response.data.data,
                        last_page: response.data.last_page,
                        loading: false,
                        program_loading: false
                    })
                } else {
                    let newdonaturs = this.state.donaturs.concat(response.data.data)
                    this.setState({
                        donaturs: newdonaturs,
                        last_page: response.data.last_page,
                        loading: false,
                        program_loading: false
                    })
                }
            })
            .catch(error2 => {
                console.log(error2)
            })
    }
    countDonatur() {
        const {
            program
        } = this.state

        axios.get(Constant.COUNT_DONATURS + program.id)
            .then(response3 => {
                this.setState({
                    total_donatur: response3.data.data
                })
            })
            .catch(error3 => {
                console.log(error3)
            })
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret',
                'April', 'Mei', 'Juni', 'Juli',
                'Agustus', 'September', 'Oktober',
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", "
                + new_date.getDate() + " "
                + months[new_date.getMonth()] + " "
                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }
    newTabClickedW() {
        let linkWhatsapp = window.open('https://wa.me/?text=https://temanpeduli.com/detailprogram/' + this.program.seo, '_blank')
        linkWhatsapp.focus()
    }
    newTabClickedI() {
        let linkInstagram = window.open('https://www.instagram.com/temanpedulicom/', '_blank')
        linkInstagram.focus()
    }

    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }

    render() {
        const { match, match: { params } } = this.props
        const {
            program, image_link, go_back, go_donate,
            target, collected, percentage, final_day, donatur, total_donatur, donaturs, infoProgram,
            collapse_donatur, collapse_perkembangan, program_loading, event_avaibility, event_seo, events
        } = this.state

        let seo = program.seo

        let url = this.props.location.pathname.split('/')
        let pls = null

        // let linkFacebook = "https://www.facebook.com/sharer/sharer.php?u=https://mizanamanah.or.id/detailprogram/"+program.seo
        // let linkTwitter = "http://twitter.com/intent/tweet?url="+program.judul+" Klik Link Berikut https://mizanamanah.or.id/detailprogram/"+program.seo
        // let linkEmail = "mailto:?Subject=&Body="+program.judul+" "+program.resume+" Klik Link Berikut https://mizanamanah.or.id/detailprogram/"+program.seo
        // let linkWhatsapp = "https://api.whatsapp.com/send?text="+program.judul+" "+program.resume+" Klik Link Berikut https://mizanamanah.or.id/detailprogram/"+program.seo

        // let linkWhatsapp = window.open('https://wa.me/?text=https://temanpeduli.com/detailprogram/' + ,  '_blank')


        if (url[3]) {
            pls = seo + "/" + url[3]
        } else {
            pls = seo
        }


        // if (this.props.match.url === `/detail/${program.seo}`)
        //     window.location.href = `/detailprogram/${program.seo}`

        let today = Date.now()
        let day_remaining = this.dateDiff(today, final_day)

        let donateAvaibility = "";
        if (day_remaining < 0 && final_day != null) {
            donateAvaibility = <button style={{ textAlign: 'center', width: '50%', cursor: 'pointer' }} className="button-secondary bg-highlight button-m button-full button-round-medium shadow-small">DONASI SEKARANG</button>
        } else {
            donateAvaibility = <Link to={'/donateform/' + pls} style={{ textAlign: 'center', width: '50%' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">DONASI SEKARANG</Link>
        }

        let donateButton = "";
        if (day_remaining < 0 && final_day != null) {
            donateButton = <button style={{ textAlign: 'center', width: '100%', cursor: 'pointer' }} className="button-secondary bg-highlight button-m button-full button-round-medium shadow-small">DONASI SEKARANG</button>
        } else {
            donateButton = <Link to={'/donateform/' + pls} style={{ textAlign: 'center', width: '100%' }} className="button bg-highlight button-m button-full button-round-medium shadow-small">DONASI SEKARANG</Link>
        }



        if (go_back)
            return <Redirect exact to='/programs' />
        // return <Redirect exact to='/home' />
        else if (go_donate)
            return <Redirect exact to={`/donateform/${pls}`} />

        return (<div className="page-content">
            <div className="header header-fixed header-logo-app header-auto-show">
                <div className="res">
                    <Link to={'/programs'} >
                        <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                        {String(program.judul).substring(0, 32)}...
                            </Link>
                </div>
            </div>

            <Header back_url="/programs" />

            <br></br>

            {/* <section id="btn-donasi-bawah" onClick={() => this.setState({ go_donate: true })}>
                    <p className="txt-btn-donasi" style={{borderRadius: 7+"px", marginBottom: 15+"px"}}>DONASI SEKARANG</p>
                </section> */}
            <div className="bg-donasi" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                {event_avaibility === true ?
                    <>
                        <button style={{ textAlign: 'center', width: '50%', marginRight: '5px', cursor: 'pointer' }} className="button bg-highlight button-m button-full button-round-medium shadow-small" onClick={() => this.setState({ setIsOpen: true })}>LIHAT EVENT</button>
                        {donateAvaibility}
                    </>
                    :
                    <>
                        {donateButton}
                    </>
                }
            </div>

            <div className="content-boxed" id="programs_scroller" style={{ marginTop: 0 }}>
                <div className="content">
                    <LoadingIndicator />
                    {program_loading == false ?
                        <>
                            <img className="caption-image owl-lazy" style={{ borderRadius: 13 + "px" }} src={image_link} /><br />
                            <h3 style={{ lineHeight: 1.2 + 'em' }}>{program.judul}</h3>
                            {program.program.partner != null ? 
                                <i><p className="partner-program">Dibuat Oleh {program.program.partner.pic_name}</p></i>
                                :
                                <i><p className="partner-program">Dibuat Oleh Yayasan {program.program.yayasan.nama}</p></i>
                            }
                            <div className="float-left">
                                <p>Batas Waktu</p>
                            </div>
                            <div className="float-right">
                                <p>
                                    {day_remaining < 1 && final_day !== null ?
                                        <>
                                            (Masa Penggalangan Dana Sudah Berakhir)
                                            </>
                                        :
                                        <>
                                            {this.dateFormat(final_day)}
                                        </>
                                    }
                                </p>
                            </div>
                            <br /><br />
                            <div className="w3-light-grey">
                                <div className="w3-red" style={{ height: 5 + "px", width: `${percentage}%` }}>
                                </div>
                            </div>
                            <p>{donatur} Donasi</p><br />
                            <div className="float-left">
                                <p className="txt-batas-waktu">Terkumpul Rp. {this.numberFormat(collected)}</p>
                            </div>
                            <div className="float-right">
                                <p className="txt-batas-waktu">Target Rp. {(target) ? this.numberFormat(target) : "∞"}</p>
                            </div>
                            {/* {this.numberFormat(program.total)} dari Rp. {(program.dana_target) ? this.numberFormat(program.dana_target) : "∞"} */}
                            <br /><br /><br />
                            <div className="content-detail" style={{ width: 100 + '% !important' }}>
                                {ReactHtmlParser(program.deskripsi)}
                            </div>
                            <div className="content-detail">
                                <hr />
                                <div style={{ textAlign: 'center' }}>
                                    <h5>Bagikan</h5>
                                    <p>Mari berbagi informasi yang Insya Allah bermanfaat kepada rekan dan saudara kita. Semoga menjadi amal soleh yang membawa keberkahan untuk anda. klik tombol share di bawah ini.</p>
                                </div>

                                {/* <div style={{display: 'flex', marginTop: 10+'px'}}>
                                    <div style={{marginRight: 10+'px'}}>
                                        <a href="#" onClick={() => this.newTabClickedW()}>
                                            <img src="/wa.png" style={{maxWidth: '50px'}}/>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="#" onClick={() => this.newTabClickedI()}>
                                            <img src="/ig.png" style={{maxWidth: '50px'}}/>
                                        </a>
                                    </div>
                                </div> */}
                                <table>
                                    <thead>
                                        <tr>
                                            <td>
                                                <div className="float-right">
                                                    <a style={{ marginRight: 10 + 'px', cursor: 'pointer' }} onClick={() => this.newTabClickedW()}>
                                                        <img src="/wa2.png" style={{ maxWidth: '50px' }} />
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="float-left">
                                                    <a style={{ marginLeft: 10 + 'px', cursor: 'pointer' }} onClick={() => this.newTabClickedI()}>
                                                        <img src="/ig2.png" style={{ maxWidth: '50px' }} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                                <hr />
                                <h5 style={{ cursor: 'pointer' }} onClick={() => this.setState({ collapse_perkembangan: !collapse_perkembangan })}>Lihat Perkembangan Program</h5>
                                <br />
                                <Collapse isOpened={collapse_perkembangan}>
                                    <div className="card-program">
                                        {infoProgram.length == 0 ?
                                            <center>
                                                <label>Belum ada perkembangan untuk program ini</label>
                                            </center>
                                            :
                                            infoProgram.map((val, i) => {
                                                return (
                                                    <div className="item-donatur" key={i}>
                                                        <div className="box-nominal">
                                                            <label style={{ fontSize: '12px' }}>{val.judul}</label>
                                                            <h5>{ReactHtmlParser(val.content)}</h5>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Collapse>

                                <hr />

                                <h5 style={{ cursor: 'pointer' }} onClick={() => this.setState({ collapse_donatur: !collapse_donatur })}>Donatur ({total_donatur})</h5>
                                <br />
                                <Collapse isOpened={collapse_donatur}>
                                    <div className="card-program">
                                        {donaturs.map((pendonasi, i) => {
                                            let foto = null
                                            if (pendonasi.guest != null) {
                                                foto = 'hub.png'
                                            } else {
                                                if (pendonasi.user != null) {
                                                    foto = Constant.GET_PROFILE_PICTURE + pendonasi.user.id
                                                } else {
                                                    foto = 'https://mizanamanah.or.id/admin/assets/media/icons/icon-people.png'
                                                }
                                            }
                                            return (
                                                <div className="item-donatur" key={i}>
                                                    <div className="img-donatur" style={{ backgroundImage: `url(${foto})` }}></div>
                                                    <div className="box-nominal">
                                                        {(pendonasi.guest) ? pendonasi.guest.nama_lengkap : (pendonasi.user) ? pendonasi.user.name : '-'}
                                                        <h5>Rp. {this.numberFormat(pendonasi.total_donasi)}</h5>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </div>
                        </>
                        :
                        <>
                            <div>
                                <center>
                                    {/* <label style={{ marginTop: '10px', color: '#3DADAA' }}>Sedang Mengunduh Data Program</label> */}
                                </center>
                            </div>
                        </>
                    }
                </div>
            </div>

            <Modal
                isOpen={this.state.setIsOpen}
                onRequestClose={() => this.setState({ setIsOpen: false })}
                contentLabel="Example Modal"
                style={customStyles}
                className="menu round-medium res"
                closeTimeoutMS={300}>
                <div className="res">
                    <div className="filter">
                        <div>
                            <h3 style={{ marginBottom: 30 + 'px' }}>Daftar Event</h3>
                            <div>
                                {events.map((event, i) => {
                                    let image_link = Constant.GET_EVENT_PROGRAMS_IMAGE + event.event_program_id
                                    let fase = event.phase;
                                    let today = Date.now();
                                    let day_remaining = this.dateDiff(today, event.close_registration);

                                    if (fase === 'registration') {
                                        if (day_remaining < 0) {
                                            fase = "Dalam Persiapan"
                                        } else {
                                            fase = "Open Registration"
                                        }
                                    } else if (fase === 'on going') {
                                        fase = "Sedang Berjalan"
                                    } else if (fase === 'done') {
                                        fase = "Event Selesai"
                                    }
                                    return (
                                        <Link to={"/event/" + event.seo} key={i}>
                                            <div className="content-boxed" id="program-list">
                                                <div className="content" id="program-list-content">
                                                    <div className="program-home-image" style={{ backgroundImage: `url(${image_link})` }}>
                                                        <div className="status-program" style={{ backgroundColor: '#3DADAA' }}>{fase}</div>
                                                    </div>
                                                    <div className="program-desc">
                                                        <h6 className="title-program">{String(event.judul).substring(0, 20)}</h6>
                                                        <h2 className="event-resume">Tim Terdaftar: {event.event_program_participant.length}</h2>
                                                        <div className="event-resume">{String(event.resume).substring(0, 50)}...</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


        </div>
        )
    }
}

export default DetailProgram