import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import Header from '../Components/Header'
import { Plus, Minus } from 'react-feather'
import * as Constant from '../Constant'
import axios from 'axios'
import { MetodePembayaran, Input, Nominal } from '../Components/FormComponents';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import Select from 'react-select';

import Cookies from 'universal-cookie';
Modal.setAppElement('#root');

const customStyles = {
    content: {
        position: 'relative',
        top: '25px',
        bottom: '10px',
        right: '0px',
        width: '400px',
        border: '0px',
        borderRadius: '15px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};

const cookies = new Cookies();

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchandise: [],
            image_link: '',
            loading: true,
            donation_type: [],
            total_order: 1,
            nominals: '',
            subtotal: 0,
            metode_pengiriman: '',
            province_id: '',
            city_id: '',
            province_name: '',
            city_name: '',
            postal_code: '',
            alamat_lengkap: '',
            full_name: '',
            phone_number: '',
            email: '',

            setIsOpen: false,
            checkoutModal: false,

            ekspedisi_pengiriman: [
                { value: 'jne', label: 'JNE' },
                { value: 'jnt', label: 'J&T' },
                { value: 'tiki', label: 'TIKI' },
                { value: 'pos', label: 'POS Indonesia' },
                { value: 'ninja', label: 'Ninja Xpress' },
                { value: 'sicepat', label: 'SiCepat' },
            ],
            province: [],
            city: [],
            list_paket: [],

            ongkir: 0,
            kurir: '',
            paket_pengiriman: '',
            estimasi_pengiriman: '',

            selected_method: '',
            selected_vendor: '',

            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            no_rekening: ''
        }
    }

    componentDidMount() {
        this.getMerchandise()
        // this.getRajaOngkirAPI()
    }

    getRajaOngkirAPI() {
        const { province_id, city_id } = this.state;
        axios.get(Constant.GET_PROVINSI).then(
            response => {
                let data = response.data.data.rajaongkir.results;
                let province = new Array();
                data.forEach(val => {
                    province.push({
                        value: val.province_id,
                        label: val.province
                    });
                })
                this.setState({
                    province: province
                });
            }
        )
        axios.get(Constant.GET_KOTA + "?province=" + province_id).then(
            response => {
                let data = response.data.data.rajaongkir.results;
                let city = new Array();
                data.forEach(val => {
                    city.push({
                        value: val.city_id,
                        label: val.type + " " + val.city_name
                    });
                });
                this.setState({
                    city: city
                });
            }
        )
    }

    getMerchandise() {
        let { match: { params } } = this.props;
        trackPromise(
            axios.get(Constant.GET_MERCHANDISE_DETAIL + params.productKey)
                .then(response => {
                    let data = response.data.data;
                    this.setState({
                        merchandise: data,
                        image_link: Constant.GET_MERCHANDISE_IMAGE + data.id
                    })
                    this.getDonationNominals()
                })
        )
    }

    getDonationNominals() {
        const { merchandise, ongkir } = this.state
        let merch_id = merchandise.id
        axios.get(Constant.GET_MERCHANDISE_NOMINAL + merch_id).then(
            response => {
                if (response.data.data) {
                    this.setState({
                        donation_type: response.data.data,
                        subtotal: response.data.data.value,
                        loading: false,
                    });
                } else {
                    this.getNominal()
                }
            }
        )
    }

    getNominal() {
        axios.get(Constant.GET_NOMINAL_BUTTON).then(
            response => {
                this.setState({
                    nominals: response.data.data,
                    loading: false,
                })
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    onPlusButtonClicked(value) {
        const { subtotal, total_order } = this.state
    }
    onMinusButtonClicked(value) {
        const { subtotal, total_order } = this.state
    }

    callbackVendor = (callVendor, callMethod) => {
        this.setState({ selected_method: callMethod, selected_vendor: callVendor, setIsOpen: false, checkoutModal: true });
    }

    callbackNominal = (nominal) => {
        this.setState({ donation: nominal });
    }

    handleProvinceChange(event) {
        this.setState({
            province_id: event.value,
            province_name: event.label,
            city_id: '',
            city_name: '',
            list_paket: [],
            city: []
        }, () => this.getRajaOngkirAPI());
    }

    handlePengirimanChange(event) {
        const { merchandise, city_id, metode_pengiriman } = this.state;
        let data = {
            'merchandise_id': merchandise.id,
            'destination': city_id,
            'courier': event.value
        }
        this.setState({ kurir: event.value })
        /*
        axios.post(Constant.CEK_ONGKIR, data).then(
            response => {
                let data = response.data.data.rajaongkir.results[0];
                console.log(data)
                let options = [];
                data.costs.forEach(val => {
                    let estimasi = val.cost[0].etd;
                    estimasi = estimasi.split(" ")
                    let time = estimasi[0];
                    let duration = "hari"
                    if (estimasi.length > 1) {
                        duration = estimasi[1].toLowerCase();
                    }
                    estimasi = time + " " + duration
                    options.push({ value: val.service + "_" + estimasi + "_" + val.cost[0].value, label: val.service + " (Estimasi " + estimasi + ") - Rp " + this.numberFormat(val.cost[0].value) })
                });
                this.setState({
                    list_paket: options,
                    metode_pengiriman: event.value,
                    kurir: data.name
                });
            }
        )
        */
    }

    handleServiceChange(event) {
        const { ongkir, estimasi_pengiriman, paket_pengiriman, subtotal } = this.state;
        let value = event.value;
        value = value.split("_");
        this.setState({
            paket_pengiriman: value[0],
            estimasi_pengiriman: value[1],
            ongkir: value[2],
            subtotal: subtotal + parseInt(value[2])
        })
    }

    handleCityChange(event) {
        this.setState({
            city_id: event.value,
            city_name: event.label,
            list_paket: []
        });
    }

    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }

    onMinusButtonClicked() {
        const { total_order, donation_type, ongkir } = this.state;
        let newValue = total_order - 1;
        if (newValue < 1) {
            newValue = 1;
        }
        let subtotal = newValue * donation_type.value;
        this.setState({ total_order: newValue, subtotal: subtotal + parseInt(ongkir) })
    }

    onPlusButtonClicked() {
        const { total_order, donation_type, ongkir } = this.state;
        let newValue = total_order + 1;
        let subtotal = newValue * donation_type.value;
        this.setState({ total_order: newValue, subtotal: subtotal + parseInt(ongkir) })
    }

    onAmountChanged(e) {
        const { donation_type, total_order, ongkir } = this.state
        const { name, value } = e.target
        let subtotal = donation_type.value * total_order
        let newValue = parseInt(value.split('.').join('').split(',').join(''));
        if (newValue < subtotal + parseInt(ongkir)) {
            newValue = subtotal + parseInt(ongkir)
        }
        this.setState({ [name]: newValue })
    }
    onSuggestButtonClicked(value) {
        const { donation_type, total_order, ongkir } = this.state
        let newValue = parseInt(value.replace(/\./g, ''));
        let subtotal = donation_type.value * total_order;
        if (newValue < subtotal + parseInt(ongkir)) {
            newValue = subtotal + parseInt(ongkir)
            alert('Tidak bisa mengubah nilai jika lebih kecil dari subtotal ditambah ongkir.')
        }
        this.setState({ subtotal: newValue })
    }

    hitungTagihan() {
        const { donation_type, total_order, subtotal,
            selected_method, selected_vendor, kurir,
            metode_pengiriman, alamat_lengkap, city_id, province_id, merchandise, postal_code,
            full_name, email, phone_number } = this.state
        if (full_name === "") {
            alert("Mohon isi nama lengkap anda");
        } else if (email === "") {
            alert("Mohon isi email anda")
        } else if (phone_number === "") {
            alert("Mohon isi nomor telepon anda");
        } else if (kurir === "") {
            alert("Mohon pilih metode pengiriman");
        } /* else if (province_id === "") {
            alert("Mohon pilih provinsi anda");
        } else if (city_id === "") {
            alert("Mohon pilih kota anda");
        } */ else if (postal_code === "") {
            alert("Mohon isi kode pos");
        } else if (alamat_lengkap === "") {
            alert("Mohon isi alamat lengkap");
        } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            this.setState({ setIsOpen: true })
        } else {
            alert("Email tidak valid")
        }
    }

    commitPesan(event) {
        if (!event.detail || event.detail == 1) {
            document.getElementById("btnPesan").disabled = true;
            this.pesan()
        }
    }

    pesan() {
        const { full_name, phone_number, email, alamat_lengkap, province_name, city_name, kurir, estimasi_pengiriman, paket_pengiriman, postal_code,
            donation_type, total_order, merchandise, subtotal, selected_method, selected_vendor,
            card_number, exp_month, exp_year, cvn, ongkir } = this.state

        let metode_pembayaran = null
        if (selected_vendor.payment_type == 'virtual_account') {
            metode_pembayaran = selected_vendor.midtrans_code
        } else if (selected_vendor.payment_name == "Alfamart") {
            metode_pembayaran = selected_vendor.payment_name.toUpperCase()
        } else {
            metode_pembayaran = selected_vendor.payment_name
        }

        let array = []
        if (selected_vendor.payment_type == 'credit_card') {
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }


        const parameter = {
            'key_yayasan': Constant.KEY_YAYASAN,
            'merchandise_id': merchandise.id,
            'nama_lengkap': full_name,
            'no_handphone': phone_number,
            'email': email,
            'alamat_lengkap': alamat_lengkap + ", " + city_name + ", " + province_name + ", " + postal_code,

            'subtotal': subtotal,
            'harga': donation_type.value,
            'shipping_location': alamat_lengkap + ", " + city_name + ", " + province_name + ", " + postal_code,
            'courier': kurir,
            'service': paket_pengiriman,
            'estimated_time': estimasi_pengiriman,
            'shipping_fee': ongkir,
            'payment_method': metode_pembayaran,
            'device': 'PWA',
            'qty': total_order,

            'vendor': selected_vendor.vendor,
            'payment_type': selected_vendor.payment_type,
            'postal_code': postal_code,

            'no_rekening': card_number
        }

        axios.post(Constant.ORDER_MERCHANDISE, parameter)
            .then(response => {
                if (response.data.snap_token != null) {
                    window.snap.pay(response.data.snap_token, {
                        onSuccess: function (result) {
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                        },
                        // Optional
                        onPending: function (result) {
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0] + '//' + pisah_url[2] + '/notif/notif-wait'
                        },
                        // Optional
                        onError: function (result) {
                            console.log("gagal")
                            document.getElementById("btnPesan").disabled = false;
                        },
                        onClose: function (result) {
                            console.log(response.data)
                            let id = ''
                            if (selected_vendor.vendor == "midtrans") {
                                id = response.data.snap_token
                            }
                            const data_send = {
                                iDtoken: id,
                                status: 1,
                                key_yayasan: Constant.KEY_YAYASAN
                            }
                            axios.post(Constant.CANCEL_ORDER_MERCHANDISE, data_send)
                                .then(response => {
                                    alert("Transaksi Dibatalkan")
                                    document.getElementById("btnPesan").disabled = false;
                                })
                                .catch(error => {
                                    console.log(error)
                                    document.getElementById("btnPesan").disabled = false;
                                })
                        }
                    })
                }
            })

    }


    render() {
        const { merchandise, image_link, loading, donation_type, nominals, total_order, province, city,
            province_id, city_id, postal_code, ekspedisi_pengiriman, metode_pengiriman, subtotal, setIsOpen,
            full_name, phone_number, email, selected_method, selected_vendor, checkoutModal, alamat_lengkap, list_paket,
            estimasi_pengiriman, ongkir, paket_pengiriman, city_name, province_name,
            card_number, exp_year, exp_month, cvn } = this.state
        return (
            <div className="page-content">
                <Header back_url={'/merchandise'} />
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <Link to={'/'}>
                            <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                            {merchandise.product_name}
                        </Link>
                    </div>
                </div>

                {loading == false &&
                    <>
                        <div className="content-boxed" style={{ marginTop: '20px', marginBottom: '10px' }}>
                            <div className="content" id="program-list-content">
                                <div className="program-home-image" style={{ backgroundImage: `url(${image_link})` }}></div>
                                <div className="program-desc">
                                    <p style={{ marginBottom: 0 }}>Produk yang di pilih :</p>
                                    <b><p className="title-program">{merchandise.product_name}</p></b>
                                    <p style={{ marginBottom: 0 }}>Harga Satuan <b>Rp {this.numberFormat(merchandise.value)}</b></p>
                                    <p style={{ marginBottom: 0 }}>Total Order</p>
                                    <b>
                                        <div style={{ display: 'flex' }}>
                                            <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onMinusButtonClicked()} >
                                                <Minus />
                                            </a>
                                            <input type="text" name="total_order" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', marginLeft: '10px', marginRight: '10px', padding: 10 + 'px', textAlign: 'center' }} id="donation" className="input-form-donasi" value={total_order} readOnly />
                                            <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onPlusButtonClicked()}>
                                                <Plus />
                                            </a>
                                        </div>
                                    </b>
                                </div>
                            </div>
                        </div>

                        <div className="content-boxed" style={{ paddingTop: '0px' }}>
                            <section id="donasi-box">
                                <div id="btn_for_login">
                                    <br />
                                    <center style={{ fontSize: 12 + 'px', color: '#8a8a8a' }}>
                                        Silahkan isi data di bawah ini
                                    </center>
                                </div>
                                <Input type="text" name="full_name" value={full_name} placeholder="Nama Lengkap" callback={this.callbackInput} />
                                <Input type="number" name="phone_number" value={phone_number} placeholder="Nomor Ponsel atau Whatsapp" callback={this.callbackInput} />
                                <Input type="email" name="email" value={email} placeholder="Email" callback={this.callbackInput} />
                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Metode Pengiriman</p>
                                <Select options={ekspedisi_pengiriman} onChange={(e) => this.handlePengirimanChange(e)} placeholder="Silahkan Pilih Kurir Pengiriman..." />
                                <p style={{ lineHeight: '14px', marginBottom: 0 }}>* Service yang digunakan berupa COD (Cash On Delivery). </p>
                                <p style={{ marginBottom: 0 }}>Kode Pos</p>
                                <Input type="number" name="postal_code" placeholder="Silahkan Isi Kode Pos Tujuan..." callback={this.callbackInput} value={postal_code} />
                                <p style={{ marginBottom: 0 }}>Alamat Lengkap</p>
                                <textarea className="input-dr-donasi" style={{ textAlign: 'left' }} placeholder="Alamat Lengkap..." onChange={(e) => this.setState({ alamat_lengkap: e.target.value })}>{alamat_lengkap}</textarea>
                                {/*
                                <p style={{ marginBottom: 0 }}>Provinsi</p>
                                <Select options={province} onChange={(e) => this.handleProvinceChange(e)} placeholder="Silahkan Pilih Provinsi Tujuan..." />
                                {province_id !== "" &&
                                    <>
                                        <p style={{ marginBottom: 0 }}>Kota</p>
                                        <Select options={city} onChange={(e) => this.handleCityChange(e)} placeholder="Silahkan Pilih Kota Tujuan..." />
                                        <p style={{ marginBottom: 0 }}>Kode Pos</p>
                                        <Input type="number" name="postal_code" placeholder="Silahkan Isi Kode Pos Tujuan..." callback={this.callbackInput} value={postal_code} />
                                        <p style={{ marginBottom: 0 }}>Alamat Lengkap</p>
                                        <textarea className="input-dr-donasi" style={{ textAlign: 'left' }} placeholder="Alamat Lengkap..." onChange={(e) => this.setState({ alamat_lengkap: e.target.value })}>{alamat_lengkap}</textarea>
                                    </>
                                }
                                {province_id !== "" && city_id !== "" && postal_code !== "" && alamat_lengkap !== "" &&
                                    <>
                                        <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Metode Pengiriman</p>
                                        <Select options={ekspedisi_pengiriman} onChange={(e) => this.handlePengirimanChange(e)} placeholder="Silahkan Pilih Kurir Pengiriman..." />
                                        {metode_pengiriman !== "" &&
                                            <>
                                                <p style={{ fontSize: '14px' }}>Service</p>
                                                <Select options={list_paket} style={{ marginTop: '10px' }} placeholder="Paket Pengiriman..." onChange={(e) => this.handleServiceChange(e)} />
                                            </>
                                        }
                                    </>
                                }
                                */}
                                {donation_type !== null &&
                                    <>
                                        {donation_type.type === "minimum" &&
                                            <>
                                                <p style={{ marginBottom: 0 }}>Total </p>
                                                <b><input type="type" name="subtotal" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', padding: 10 + 'px' }} className="input-form-donasi" value={this.numberFormat((subtotal) ? subtotal : 0)} onChange={(e) => this.onAmountChanged(e)} /></b>
                                                <div className="sugest-box">
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat(donation_type.value))}>
                                                        {this.numberFormat(donation_type.value)}
                                                    </a>
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat("50.000"))}>
                                                        50.000
                                                    </a>
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat("100.000"))}>
                                                        100.000
                                                    </a>
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat("200.000"))}>
                                                        200.000
                                                    </a>
                                                </div>
                                                <p style={{ marginBottom: 0 }}>* Nominal lebih akan didonasikan ke program {merchandise.judul}. <Link to={'/detailprogram/' + merchandise.seo} style={{ color: '#1a0dab' }}>Klik disini untuk melihat program terkait.</Link> </p>
                                            </>
                                        }
                                        {donation_type.type === "kelipatan" &&
                                            <>
                                                <p style={{ marginBottom: 0 }}>Total </p>
                                                <b>
                                                    <div style={{ display: 'flex' }}>
                                                        <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onMinusButtonClicked(donation_type.value)}>
                                                            <Minus />
                                                        </a>
                                                        <input type="text" name="subtotal" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', marginLeft: '10px', marginRight: '10px', padding: 10 + 'px' }} id="donation" className="input-form-donasi" value={this.numberFormat((subtotal) ? subtotal + parseInt(ongkir) : 0)} onChange={(e) => this.onAmountChanged(e)} readOnly />
                                                        <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onPlusButtonClicked(donation_type.value)}>
                                                            <Plus />
                                                        </a>
                                                    </div>
                                                </b>
                                                <p style={{ marginBottom: 0 }}>* Nominal lebih akan didonasikan ke program {merchandise.judul}. <Link to={'/detailprogram/' + merchandise.seo} style={{ color: '#1a0dab' }}>Klik disini untuk melihat program terkait.</Link> </p>
                                            </>
                                        }
                                    </>
                                }

                                <a className="donasi" style={{ cursor: 'pointer' }} onClick={() => this.hitungTagihan()}>Hitung Pesanan</a>
                            </section>
                        </div>

                        <Modal
                            isOpen={setIsOpen}
                            onRequestClose={() => this.setState({ setIsOpen: false })}
                            contentLabel="Detail Pembayaran"
                            style={customStyles}
                            className="menu round-medium res"
                            closeTimeoutMS={300}>
                            <div className="res" style={{ overflow: 'auto' }}>
                                <div className="filter">
                                    <h5 style={{ marginBottom: 15 + 'px' }}>Detail Tagihan</h5>
                                    <div className="content" style={{ marginLeft: '0px' }}>
                                        <div style={{ display: 'flex', marginTop: '10px' }}>
                                            <img src={image_link} style={{ width: '25%', maxHeight: '200px' }} />
                                            <div style={{ width: '75%', marginLeft: '10px' }}>
                                                <b><p className="title-program">{merchandise.product_name}</p></b>
                                                <p style={{ marginBottom: 0 }}>Harga Satuan: <b>Rp {this.numberFormat(donation_type.value)}</b></p>
                                            </div>
                                        </div>
                                        <hr style={{ marginBottom: '10px', marginTop: '10px' }} />
                                        <center style={{ fontSize: 12 + 'px', color: '#8a8a8a', marginTop: '10px' }}>
                                            Rincian Tagihan
                                        </center>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Harga Satuan</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(donation_type.value)}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Qty</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>{total_order}</p>
                                        </div>
                                        <hr style={{ marginBottom: '0px', marginTop: '0px' }} />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Subtotal</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(donation_type.value * total_order)}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Donasi</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(subtotal - (donation_type.value * total_order) - parseInt(ongkir))}</p>
                                        </div>
                                        {/*
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Estimasi Ongkir</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(parseInt(ongkir))}</p>
                                        </div>
                                        <hr style={{ marginBottom: '0px', marginTop: '0px', borderColor: '#ddd' }} />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>Total</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>Rp {this.numberFormat(subtotal)}</p>
                                        </div>
                                        */}
                                        <p style={{ lineHeight: '14px', marginBottom: 0 }}>* Ongkos Pengiriman ditangguhkan kepada penerima. </p>
                                        <p style={{ lineHeight: '14px', marginBottom: 0 }}>* Nominal lebih akan didonasikan ke program {merchandise.judul}. <Link to={'/detailprogram/' + merchandise.seo} style={{ color: '#1a0dab' }}>Klik disini untuk melihat program terkait.</Link> </p>
                                    </div>

                                    <MetodePembayaran callback={this.callbackVendor} />
                                </div>
                            </div>
                        </Modal>


                        <Modal
                            isOpen={checkoutModal}
                            onRequestClose={() => this.setState({ checkoutModal: false })}
                            contentLabel="Detail Pembayaran"
                            style={customStyles}
                            className="menu round-medium res"
                            closeTimeoutMS={300}>
                            <div className="res" style={{ overflow: 'auto' }}>
                                <div className="filter">
                                    <h5 style={{ marginBottom: 15 + 'px' }}>Konfirmasi Pembayaran</h5>
                                    <div className="content" style={{ marginLeft: '0px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Metode Pembayaran</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>{selected_vendor.payment_name}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Total</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>Rp {this.numberFormat(subtotal)}</p>
                                        </div>
                                        <p style={{ marginBottom: 0, fontSize: '14px' }}>Alamat Penerima</p>
                                        <p style={{ marginBottom: 0, fontSize: '14px' }}>{alamat_lengkap}, {postal_code}</p>
                                        {selected_vendor.payment_type === "virtual_account" &&
                                            <>
                                                <center style={{ fontSize: 12 + 'px', color: '#8a8a8a' }}>
                                                    Silahkan isi data di bawah ini
                                                </center>
                                                <Input type="number" name="card_number" value={card_number} placeholder="Card Number" callback={this.callbackInput} />
                                                <Input type="number" name="exp_month" value={exp_month} placeholder="Expired Month" callback={this.callbackInput} />
                                                <Input type="number" name="exp_year" value={exp_year} placeholder="Expired Year" callback={this.callbackInput} />
                                                <Input type="number" name="cvn" value={cvn} placeholder="CVN" callback={this.callbackInput} />
                                            </>
                                        }
                                        <p style={{ marginBottom: 0, fontSize: '12px' }}>* Ongkos kirim ditanggung oleh pemesan</p>
                                    </div>
                                    <button id="btnPesan" className="donasi" style={{ cursor: 'pointer', width: '100%' }} onClick={this.commitPesan}>Pesan Sekarang</button>
                                </div>
                            </div>
                        </Modal>


                    </>
                }

            </div>
        )
    };
}

export default Checkout;