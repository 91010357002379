import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import axios from 'axios'

class ProfileMizan extends Component {
    constructor() {
        super()
        this.state = {
            profile: [],
            kepengurusan: [],
            sejarah: [],

            go_home: false,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
        this.getProfile()
        this.getKepengurusan()
        this.getSejarah()
    }
    componentWillUnmount() {
        
    }
    getProfile(){
        axios.get(Constant.GET_PROFILE)
            .then(response => {
                this.setState({ 
                    profile: response.data.data[0]
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getKepengurusan(){
        axios.get(Constant.GET_PROFILE_KEPENGURUSAN)
            .then(response => {
                this.setState({ 
                    kepengurusan: response.data.data
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getSejarah(){
        axios.get(Constant.GET_PROFILE_SEJARAH)
            .then(response => {
                this.setState({ 
                    sejarah: response.data.data
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { profile, kepengurusan, sejarah, go_home } = this.state

        if (go_home)
            return <Redirect exact to='/home' />
            
            console.log(kepengurusan)

        return(
        <div>         
            <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left back" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Profile Teman Peduli
                </nav>
            </header>

            <section id="detprogram-box">
                <center><p className="zakat-title" style={{marginTop: 10+'px'}}>Profile Teman Peduli</p></center>
                <br />

                {ReactHtmlParser(profile.isi)}
                <br/>

                <b>Kepengurusan</b>
                {kepengurusan.map((pengurus, i) => {
                    let poto = Constant.GET_PROFILE_KEPENGURUSAN_FOTO + pengurus.id;
                    return(
                        <div key={i}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={poto}/>
                                    <b>{pengurus.jabatan}</b>
                                    <br/>
                                    {pengurus.nama}
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    )
                })}

                <br/>
                <b>Sejarah</b>
                {sejarah.map((history, i) => {
                    return(
                        <div key={i}>
                            <b>{history.tahun}</b>
                            <br/>
                            {ReactHtmlParser(history.sejarah_language.sejarah)}
                        </div>
                    )
                })}
            </section>
        </div>
        )
    }
}

export default ProfileMizan