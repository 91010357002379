import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;

class Update extends Component {
    constructor() {
        super()
        this.state = {
            categories: [],
            updates: [],
            page: 1,
            last_page: 1,
            search: null,
            category_id: null,
            go_home: false,
            go_detail: false,
            seo_key: '',
            loading: true,

            go_donate: false
        }

        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        this.getCategories()
        this.checkCategoryFromProps()

        document.addEventListener('scroll', this.trackScrolling)

        // Modal Filter Close
        window.onclick = function (event) {
            if (event.target == document.getElementById("modal_filter")) {
                document.getElementById("modal_filter").style.display = "none";
            }
        }

        if(this.props.location.search != null){
            this.setValueFromQueryUrl()
        }
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight  && 
            this.state.page != this.state.last_page) 
        {
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getUpdates()
        }
    }
    handleInputChange(e) {
        clearTimeout(this.timer)

        const { name, value } = e.target
        this.setState({ [name]: value, page: 1})

        this.timer = setTimeout(() => this.getUpdates(), 1000)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)
            
            this.getUpdates()
        }
    }
    categorySelected(category_id) {
        this.setState({ category_id })
        this.timer = setTimeout(() => this.getUpdates(), 250)

        document.getElementById("modal_filter").style.display = "none";
    }

    getCategories() {
        // get categories
        axios.get(Constant.GET_CATEGORIES)
            .then(response => {
                // console.log(response.data)
                this.setState({ categories: response.data.data });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getUpdates() {
        const { page, search, category_id, category_id_before } = this.state
        
        let halaman = 1;
        if(category_id != category_id_before){
            this.setState({
                page: 1
            })
            halaman = 1
        }else{
            halaman = page
        }

        let query_url = `?page=${halaman}`
        if (search)
            query_url += `&keyword=${search}`
        if (category_id)
            query_url += `&idKategori=${category_id}`
        
        this.setState({
            category_id_before: category_id
        })

        axios.get(Constant.GET_UPDATES + query_url)
            .then(response => {
                // console.log(response.data)
                if (page == 1) {
                    this.setState({ 
                        updates: response.data.data,
                        last_page: response.data.last_page,
                        loading: false
                    })
                } else {
                    if(category_id != category_id_before){
                        console.log('timpa')
                        this.setState({ 
                            updates: response.data.data,
                            last_page: response.data.last_page,
                            loading: false,
                            page: 1
                        })
                        console.log(response.data.data)
                    }else{
                        console.log('tambah')
                        let newUpdates = this.state.updates.concat(response.data.data)
                        // console.log(newUpdates)
                        this.setState({ 
                            updates: newUpdates,
                            last_page: response.data.last_page,
                            loading: false
                        })
                        console.log(response.data.data)
                    }
                }
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    setValueFromQueryUrl(){
        const { page, search, category_id } = this.state

        let url = this.props.location.search
        let split_url = String(url).substring(1).split('&')

        // console.log(split_url)
        split_url.map((query) => {
            console.log(query)
            let pisah = query.split('=')
            if(pisah[0] == "page"){
                this.setState({page: pisah[1]})
            }else if(pisah[0] == "search_query_first"){
                this.setState({search: pisah[1]})
            }else if(pisah[0] == "search_query_last"){
                this.setState({category_id: pisah[1]})
            }
        })
    }

    checkCategoryFromProps() {
        let props_category = (this.props.location.state)
                                ? this.props.location.state.category_id
                                : null

        if (props_category) this.setState({ category_id: props_category })

        this.timer = setTimeout(() => this.getUpdates(), 250)
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) ) /(1000 * 60 * 60 * 24))
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    render() {
        const { match } = this.props
        const { 
            categories, search, category_id,
            go_home, go_detail, seo_key, go_donate
        } = this.state

        if (go_home)
            return <Redirect exact to="/home" />
        else if (go_detail)
            return <Redirect exact to={`update/${seo_key}`} />
        
        return (
        <section id="program-list-box">
            <header id="header" className="header-program">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left back" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Update
                </nav>

                <a 
                    className="ic-filter"
                    onClick={() => document.getElementById("modal_filter").style.display = "block"}
                >
                    <i id="ic-sidebar" className="fa fa-filter"></i>
                </a>

                <a className="ic-search">
                    <i id="ic-sidebar" className="fa fa-search"></i>
                </a>
                <input type="text" name="search" id="search" className="form-search" placeholder="  Cari Update" value={search?search:''} onChange={(e) => this.handleInputChange(e)} onKeyDown={(e) => this.handleKeyDown(e)} />
            </header>

            {/* Filter */}
            <div id="modal_filter" className="modalfilter">
                <div className="filter-content">
                    <span 
                        className="close"
                        onClick={() => document.getElementById("modal_filter").style.display = "none"}
                    >
                        &times;
                    </span>

                    <p className="head-filter">Kategori</p>
                    <p 
                        className="all-category"
                        onClick={() => this.categorySelected(null)}
                    >
                        Semua Kategori
                    </p>
                    <div className="scroller-category">
                    {categories.map((category, i) => {
                        return(
                            <p 
                                className="item-category" 
                                value={category.id} 
                                onClick={() => this.categorySelected(category.id)}
                                key={i}
                            >
                                {category.category}
                            </p>
                        )
                    })}
                    </div>
                </div>
            </div>

            <div className="scroller-program" id="programs_scroller">
            {this.state.updates.map((update, i) => {
                let image_link = Constant.GET_UPDATES_IMAGE + update.id_posting

                return (
                    <div className="card-program" key={i}>
                        <div onClick={() => this.setState({ go_detail: true, seo_key: update.seo })}>
                            <div className="img-program" style={{backgroundImage: `url(${image_link})`, width: 100+'%'}}>
                                {/* <img src={image_link}/> */}
                            </div>

                            <p className="title-card-program">{update.judul}</p>

                            <div className="garis-card"></div>

                            <div className="tanggal-card" style={{width: 65+'%'}}>
                                <i id="ic-kalendar" className="fa fa-calendar"></i>
                                <p id="tanggal">
                                    {this.dateFormat(update.created_at)}
                                </p>
                            </div>

                            <div className="kategori-card" style={{width: 35+'%'}}>Read More>>></div>
                        </div>
                    </div>
                )
            })}
            </div>

            <div className="sweet-loading" style={{height: 50+'px'}}>
                <ClipLoader
                    css={override}
                    size={40}
                    color={"#3DADAA"}
                    loading={this.state.loading}
                />
            </div>
        </section>
        )
    }
}

export default Update