import React, { Component } from 'react'
import {
    Redirect,
    Link,
    withRouter
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'

const cookies = new Cookies();
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            gone: '',
            go_profile: false,
            go_notif: false
        }
    }

    componentDidMount() {
        // this.ifLogin()
        this.goBack = this.goBack.bind(this)
    }

    goBack() {
        this.props.history.goBack()
    }

    klikEvent() {
        console.log("asda")
    }

    handleCheck(e) {
        console.log(e)
    }

    ifLogin() {
        // console.log(cookies.get('user_id'))
        if (cookies.get('user_id') != null) {
            document.getElementById('login').hidden = true
            Axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(response => {
                this.setState({ id: response.data.id })
            }).catch(error => {
                console.log(error)
            })
        } else {
            document.getElementById('logout').hidden = true
            document.getElementById('hr1').hidden = true
            document.getElementById('riwayat_donasi').hidden = true
            document.getElementById('riwayat_zakat').hidden = true
            document.getElementById('riwayat_qurban').hidden = true
            document.getElementById('hr2').hidden = true
            document.getElementById('profile').hidden = true
            document.getElementById('ganti_password').hidden = true
            document.getElementById('ic_notify').hidden = true
            document.getElementById('profile_pic').hidden = true
            document.getElementById('header_kanan').hidden = true

            this.setState({ gone: "hidden" })
        }
    }

    render() {
        const {
            id, gone, go_profile, go_notif
        } = this.state

        let image_link = ''
        let endpoint = window.location.href.split("/")[3]
        let back_url = '';

        if (cookies.get('user_id') != null)
            image_link = Constant.GET_PROFILE_PICTURE + id

        if (go_profile)
            return <Redirect exact to='/profile' />
        else if (go_notif)
            return <Redirect exact to='/notification' />

        if (this.props.back_url != null) {
            back_url = this.props.back_url
        }
        return (
            <div>
                {/* HEADER */}
                {/* endpoint != 'hubungi' && endpoint != 'programs' && endpoint != 'update' && endpoint != 'notif' &&  */}
                <div className="page-title-large color-white">
                    {back_url !== '' &&
                        <Link to={back_url} style={{ position: 'absolute', left: '0px' }}>
                            <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                        </Link>
                    }
                    <center style={{ paddingBottom: '20px' }}>
                        <img src="/tp/white.png" />
                    </center>
                </div>
                <div className="page-title-bg dark-mode-tint" style={{ height: '210px' }}>
                    {/* contrast for dark mode */}
                </div>
                <div className="page-title-bg opacity-90 bg-highlight" style={{ height: '210px' }}>
                    {/* background color */}
                </div>
                {/* 
            <div className="header header-fixed header-logo-app header-auto-show">
                
            </div>

            <header id="header">
                <nav className="left">
                    <a href="#menu"><span>Menu</span></a>
                </nav>
                <a href="#" className="logo">
                    <img src="tp/white.png" alt="" id="logo-img" />
                </a>
                <div className="div-notif" id="header_kanan">
					<div onClick={() => this.setState({go_notif: true})}><i id="ic_notify" className="fa fa-bell" style={{color: '#ffffff', fontSize: 25+'px', visibility: `${gone}`}}></i></div>
					<div id="profile_pic" className="img-profile" style={{backgroundImage: `url(${image_link})`}} onClick={ () => this.setState({go_profile: true}) }></div>
				</div>
            </header> */}


                {/* <nav id="menu">
                <center style={{marginBottom:20+"px"}}>
                    <img src="images/logo-mizan-amanah-panjang.png" alt="" id="logo-sidebar" />
                </center>
                <ul className="links">
                    <li>
                    <Link to="/home">
                        <i id="ic-sidebar" className="fa fa-home" style={{marginRight: '20px'}}></i>
                        Home
                    </Link>
                    </li>

                    <li>
                    <Link to="/programs">
                        <i id="ic-sidebar" className="fa fa-users" style={{marginRight: '16px'}}></i>
                        Program
                    </Link>
                    </li>

                    <li>
                    <Link to="/zakat">
                        <i id="ic-sidebar" className="fa fa-hand-holding-usd"></i>
                        Zakat
                    </Link>
                    </li>

                    <li>
                    <a href="https://qurban.mizanamanah.or.id">
                        <i id="ic-sidebar" className="fa" style={{marginRight: '22px'}}><img src="/cow-face-front.png" /></i>
                        Qurban
                    </a>
                    </li>

                    <li onClick={() => console.log("clicked!")}>
                        <div onClick={() => console.log("clicked!")}>
                            <i id="ic-sidebar" className="fa fa-bookmark" style={{marginRight: '27px'}}></i>
                            Event
                        </div>

                        <div className="itm-dropdown" id="konserAmal" style={{lineHeight: 3+'em',paddingLeft: 44+'px'}}>
                            <i id="ic-sidebar" className="fa"><img src="/play-arrow.png" /></i>
                            <a href="https://konseramal.mizanamanah.or.id" style={{textDecoration: 'none',color: '#000', marginLeft: '-15px'}}>
                                Konser Amal
                            </a>
                        </div>
                    </li>

                    <li>
                    <Link to="/aqiqah">
                        <i id="ic-sidebar" className="fa"><img src="/sheep.png" /></i>
                        Aqiqah Online
                    </Link>
                    </li>

                    <li><a href="/update"><i id="ic-sidebar" className="fa fa-bookmark" style={{marginRight: '26px'}}></i>Updates</a></li>

                    <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} id="hr1"/>
                        <li id="riwayat_donasi">
                        <Link to="/riwayat_donasi">
                            <i id="ic-sidebar" className="fa fa-history"></i>
                            Riwayat Donasi
                        </Link>
                        </li>
                        
                        <li id="riwayat_zakat">
                        <Link to="/riwayat_zakat">
                            <i id="ic-sidebar" className="fa fa-history"></i>
                            Riwayat Zakat
                        </Link>
                        </li>
                        
                        <li id="riwayat_qurban">
                        <Link to="/riwayat_qurban">
                            <i id="ic-sidebar" className="fa fa-history"></i>
                            Riwayat Qurban
                        </Link>
                        </li>
                    <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} id="hr2"/>
                    
                    <li id="profile"><a href="/profile"><i id="ic-sidebar" className="fa fa-user"></i>Profil</a></li>

                    <li id="ganti_password"><a href="/profile/ganti_password"><i id="ic-sidebar" className="fa fa-key"></i>Ganti Password</a></li>

                    <li><a href="/tentang">
                        <i id="ic-sidebar" className="fa fa-info-circle"></i>
                            Profil Teman Peduli
                    </a></li>
                    
                    <li><a href="/rekening-donasi">
                        <i id="ic-sidebar" className="fa"><img src="/bank.png" /></i>
                            Rekening Donasi
                    </a></li>
                    
                    <li><a href="/kantor-cabang">
                        <i id="ic-sidebar" className="fa"><img src="/business-and-trade.png" /></i>
                            Kantor Cabang
                    </a></li>
                    
                    <li><a href="/syarat-dan-ketentuan">
                        <i id="ic-sidebar" className="fa"><img src="/agreement.png" /></i>
                            Syarat dan ketentuan
                    </a></li>
                    
                    <li><a href="/ketentuan-privasi">
                        <i id="ic-sidebar" className="fa"><img src="/insurance.png" /></i>
                            Ketentuan Privasi
                    </a></li>
                    
                    <li><a href="/laporan-audit">
                        <i id="ic-sidebar" className="fa"><img src="/sales.png" /></i>
                            Laporan Audit
                    </a></li>

                    <li id="login">
                    <Link to="/login">
                        <i id="ic-sidebar" className="fa fa-sign-in-alt"></i>
                        Login
                    </Link>
                    </li>

                    <li id="logout" style={{color: '#E50000'}}>
                    <Link to="/logout">
                        <i id="ic-sidebar" className="fa fa-sign-out-alt"></i>
                        Logout
                    </Link>
                    </li>
                </ul>
            </nav> */}

            </div>
        )
    }
}

export default withRouter(Header) 
