import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from 'universal-cookie';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3DADAA;
`;


const customStyles = {
    content: {
        top: '10px',
        bottom: '10px',
        right: '0px',
        width: '260px',
        overflow: 'scroll',
        border: '0px',
        borderRadius: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};
Modal.setAppElement('#root');

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader type="ThreeDots" color={'#3DADAA'} height="60" width="60" />
        </div>
    )
}

const cookies = new Cookies();

class EventProgram extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
            page: 1,
            last_page: 1,
            search: null,
            go_home: false,
            go_detail: false,
            seo_key: '',
            loading: false,

            go_donate: false,
            go_program: false,

            modalIsOpen: false,
            setIsOpen: false
        }

        this.timer = null;
        this.trackScrolling = this.trackScrolling.bind(this);
    }

    componentDidMount() {
        this.getId();
        if (cookies.get('id_yayasan') != null) {
            this.getEventPrograms();
        }
        document.addEventListener('scroll', this.trackScrolling)
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)

        this.getEventPrograms()
    }
    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight &&
            this.state.page != this.state.last_page) {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({
                page: next,
                loading: true
            })
            this.getEventPrograms()
            // console.log('there it is')
        }
    }

    getEventPrograms() {
        const { page, search, category_id, category_id_before } = this.state

        let halaman = 1;
        if (category_id != category_id_before) {
            this.setState({
                page: 1
            })
            halaman = 1
        } else {
            halaman = page
        }

        let query_url = `?page=${halaman}`
        if (search)
            query_url += `&keyword=${search}`
        if (category_id)
            query_url += `&idKategori=${category_id}`

        this.setState({
            category_id_before: category_id
        })

        trackPromise(
            axios.get(Constant.GET_EVENT_PROGRAMS + query_url + `&yayasan=` + Constant.KEY_YAYASAN)
                .then(response => {
                    if (page == 1) {
                        this.setState({
                            events: response.data.data.data,
                            last_page: response.data.data.last_page,
                            loading: false
                        })
                    } else {
                        let newEvents = this.state.events.concat(response.data.data.data)
                        this.setState({
                            events: newEvents,
                            last_page: response.data.data.last_page,
                            loading: false
                        })
                    }
                })
        )
    }

    getId() {
        if (cookies.get('id_yayasan') == null) {
            axios.get(Constant.GET_YAYASAN)
                .then(res => {
                    var idc = res.data.data.id;
                    cookies.set('id_yayasan', idc);
                })
        }
    }
    handleInputChange(e) {
        clearTimeout(this.timer)

        const { name, value } = e.target
        this.setState({ [name]: value, page: 1 })

        this.timer = setTimeout(() => this.getEventPrograms(), 1000)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)

            this.getEventPrograms()
        }
    }

    dateFormat(date) {
        if (date) {
            let months = [
                'Jan', 'Feb', 'Mar',
                'Apr', 'Mei', 'Jun', 'Jul',
                'Agu', 'Sep', 'Okt',
                'Nov', 'Des'
            ]

            let new_date = new Date(date)
            let formatted_date = new_date.getDate() + " "
                + months[new_date.getMonth()] + " "
                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24))
    }

    render() {
        const { match } = this.props
        const {
            search,
            go_home, go_detail, seo_key, go_donate, go_program, events
        } = this.state
        return (
            <div id="page">
                <div>
                    <Header />
                    <div className="content">
                        <div style={{ display: 'flex' }}>
                            <div className="search-box search-color shadow-tiny round-huge bottom-0 bg-in" style={{ width: '100%' }}>
                                <i className="fa fa-search"></i>
                                <input type="text" name="search" id="search" className="form-search" placeholder="Cari Event ... " value={search ? search : ''} onChange={(e) => this.handleInputChange(e)} onKeyDown={(e) => this.handleKeyDown(e)} data-search />
                            </div>
                        </div>
                    </div>

                    <div className="scroller-program" id="programs_scroller">
                        <center>
                            <LoadingIndicator />
                        </center>

                        {this.state.events.map((event, i) => {
                            let image_link = Constant.GET_EVENT_PROGRAMS_IMAGE + event.event_program_id
                            let fase = event.phase;
                            let today = Date.now();
                            let day_remaining = this.dateDiff(today, event.close_registration); 

                            if (fase === 'registration') {
                                if (day_remaining < 0) {
                                    fase = "Dalam Persiapan"
                                } else {
                                    fase = "Open Registration"
                                }
                            } else if (fase === 'on going') {
                                fase = "Sedang Berjalan"
                            } else if (fase === 'done'){
                                fase = "Event Selesai"
                            }
                            return (
                                <Link to={"/event/" + event.seo} key={i}>
                                    <div className="content-boxed" id="program-list">
                                        <div className="content" id="program-list-content">
                                            <div className="program-home-image" style={{ backgroundImage: `url(${image_link})` }}>
                                                <div className="status-program" style={{ backgroundColor: '#3DADAA' }}>{fase}</div>
                                            </div>
                                            <div className="program-desc">
                                                <h6 className="title-program">{String(event.judul).substring(0, 20)}</h6>
                                                <div className="dana" style={{ marginTop: 10 + 'px' }}>
                                                    <div className="daftar-ditutup">
                                                        <p className="title-daftar">Pendaftaran Ditutup</p> <br />
                                                        <b><p>{this.dateFormat(event.close_registration)}</p></b>
                                                    </div>
                                                    <div className="daftar-ditutup">
                                                        <p>Event Berakhir</p> <br />
                                                        <b><p>{this.dateFormat(event.event_end)}</p></b>
                                                    </div>
                                                </div>
                                                <h2 className="event-resume">Tim Terdaftar: {event.event_program_participant.length}</h2>
                                                <div className="event-resume">{String(event.resume).substring(0, 50)}...</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}

                    </div>

                </div>

                <Footer />
            </div>
        )
    };
}

export default EventProgram;