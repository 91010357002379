import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import axios from 'axios'
import * as Constant from '../Constant'
import Cookies from 'universal-cookie';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';


const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader type="ThreeDots" color={'#3DADAA'} height="60" width="60" />
        </div>
    )
}


const customStyles = {
    content: {
        top: '10px',
        bottom: '10px',
        right: '0px',
        width: '260px',
        overflow: 'scroll',
        border: '0px',
        borderRadius: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};
Modal.setAppElement('#root');
const cookies = new Cookies();
class Index extends Component {
    constructor() {
        super()
        this.state = {
            merchandise: [],
            page: 1,
            last_page: 1,
            search: null,
            loading: false,

            setIsOpen: false,
            search: '',
            categories: [],
            category_id: null,
            categoryf: null,
            category_id_before: null,

            orderBy: 'DESC'
        }
        this.trackScrolling = this.trackScrolling.bind(this);
    }

    componentDidMount() {
        this.getId();
        if (cookies.get('id_yayasan') != null) {
            this.getMerchandise()
            this.getCategories()
        }
        document.addEventListener('scroll', this.trackScrolling)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    getMerchandise() {
        const { page, search, category_id, category_id_before, orderBy } = this.state
        let halaman = 1;
        if (page !== 1) {
            halaman = page
        }

        let query_url = `?page=${halaman}`
        if (search) {
            query_url += `&keyword=${search}`
        }
        if (category_id)
            query_url += `&idKategori=${category_id}`
        if (orderBy)
            query_url += `&orderBy=${orderBy}`


        this.setState({
            category_id_before: category_id
        })

        trackPromise(
            axios.get(Constant.GET_MERCHANDISE + query_url + `&yayasan=` + Constant.KEY_YAYASAN)
                .then(response => {
                    if (page == 1) {
                        this.setState({
                            merchandise: response.data.data.data,
                            last_page: response.data.data.last_page,
                            loading: false
                        })
                    } else {
                        if (category_id != category_id_before) {
                            this.setState({
                                merchandise: response.data.data.data,
                                last_page: response.data.data.last_page,
                                page: 1,
                                loading: false
                            })
                        } else {
                            let newMerch = this.state.merchandise.concat(response.data.data.data)
                            this.setState({
                                merchandise: newMerch,
                                last_page: response.data.data.last_page,
                                loading: false
                            })
                        }
                    }
                })
        )
    }

    getCategories() {
        // get categories
        axios.get(Constant.GET_MERCHANDISE_CATEGORIES + `?id_yayasan=` + cookies.get('id_yayasan'))
            .then(response => {
                // console.log(response.data)
                this.setState({ categories: response.data.data });
                // console.log('categories', response.data);
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }

    categorySelected(category_id, categoryf) {
        this.setState({ category_id, page: 1, categoryf, merchandise: [], setIsOpen: false })

        this.timer = setTimeout(() => this.getMerchandise(), 250)
    }

    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight &&
            this.state.page != this.state.last_page) {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({
                page: next,
                loading: true
            })
            this.getMerchandise()
            // console.log('there it is')
        }
    }

    getId() {
        if (cookies.get('id_yayasan') == null) {
            axios.get(Constant.GET_YAYASAN)
                .then(res => {
                    var idc = res.data.data.id;
                    cookies.set('id_yayasan', idc);
                })
        }
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    handleInputChange(e) {
        clearTimeout(this.timer)

        const { name, value } = e.target
        this.setState({ [name]: value, page: 1 })

        this.timer = setTimeout(() => this.getMerchandise(), 1000)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)

            this.getMerchandise()
        }
    }

    orderBy(val) {
        clearTimeout(this.timer)
        this.setState({ orderBy: val, merchandise: [], setIsOpen: false, page: 1 })

        this.getMerchandise()
    }

    render() {
        const { merchandise, setIsOpen, search, categories, categoryf } = this.state
        return (
            <>
                <div id="page">
                    <div>
                        <Header />
                        <div className="content" style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex' }}>
                                <div className="search-box search-color shadow-tiny round-huge bottom-0 bg-in">
                                    <i className="fa fa-search"></i>
                                    <input type="text" name="search" id="search" className="form-search" placeholder="Cari Merchandise ... " value={search ? search : ''} onChange={(e) => this.handleInputChange(e)} onKeyDown={(e) => this.handleKeyDown(e)} data-search />
                                </div>
                                <div className="set-icon" style={{ color: '#fff' }}>
                                    <i className="fa fa-filter fa-lg" onClick={() => this.setState({ setIsOpen: true })} style={{ cursor: 'pointer' }}></i>
                                </div>
                            </div>
                        </div>

                        <div className="content-boxed" style={{ marginTop: 0, backgroundColor: 'transparent', paddingTop: '5px', marginBottom: '5px' }}>
                            <div className="content" style={{ marginTop: '20px' }}>
                                { categoryf != null &&
                                    <div className="content-boxed" style={{ marginTop: '5px', marginBottom: '10px' }}>
                                        <center>
                                            <p style={{ color: '#3DADAA', fontSize: '16px', fontWeight: 'bold' }}>{categoryf == "" || categoryf == null ? 'Kategori Semua' : 'Kategori ' + categoryf}</p>
                                            <LoadingIndicator />
                                        </center>
                                    </div>
                                }
                                <div className="scroller-merch" id="programs_scroller">
                                    {merchandise.map((val, i) => {
                                        let image_link = Constant.GET_MERCHANDISE_IMAGE + val.id;
                                        return (
                                            <div className="item-merch">
                                                <div className="image-merch" style={{ backgroundImage: `url(${image_link})` }}></div>
                                                <p className="harga-minimal">Harga Minimal</p>
                                                <p className="harga-merch">Rp {this.numberFormat(val.value)}</p>
                                                <p className="nama-merch">{val.product_name}</p>
                                                <Link to={'/merchandise/' + val.product_key} className="btn-merch">Lihat Detail</Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />



                {/* FILTER */}

                <Modal
                    isOpen={this.state.setIsOpen}
                    onRequestClose={() => this.setState({ setIsOpen: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    className="menu menu-box-right round-medium res"
                    closeTimeoutMS={300}>
                    <div className="res">
                        <div className="filter">
                            <div>
                                <h4 style={{ marginBottom: 10 + 'px' }}><i className="fa fa-filter"></i> Filter</h4>
                                <h5 style={{ fontSize: '16px' }}>Berdasarkan Harga</h5>
                                <div>
                                    <div style={{ cursor: 'pointer' }}>
                                        <p value={'ASC'} onClick={() => this.orderBy('ASC')}>Harga Terendah</p>
                                        <hr style={{ margin: 0 }} />
                                    </div>
                                    <div style={{ cursor: 'pointer' }}>
                                        <p value={'DESC'} onClick={() => this.orderBy('DESC')}>Harga Tertinggi</p>
                                        <hr style={{ margin: 0 }} />
                                    </div>
                                </div>
                                <h5 style={{ fontSize: '16px', marginTop: '10px' }}>Kategori</h5>
                                <div>
                                    {categories.map((category, i) => {
                                        return (
                                            <div key={i} style={{ cursor: 'pointer' }}>
                                                <p value={category.id} onClick={() => this.categorySelected(category.id, category.category)}>{category.category}</p>
                                                <hr style={{ margin: 0 }} />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{ marginTop: 50 + 'px', bottom: 0 + 'px', cursor: 'pointer' }}>
                                    <p className="filter-btn" onClick={() => this.categorySelected(null)}>Semua Kategori</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Index
