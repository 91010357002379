import React, { Component } from 'react'
// import * as Constant from '../Constant'
import {
    Redirect,
} from 'react-router-dom'
import Mitra from '../../Components/Mitra'

class AfterPay extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        // 
    }

    render() {
        const { go_home } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div className="page-content" style={{paddingBottom: 0}}>         
            {/* <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left back" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Notifikasi
                </nav>
            </header> */}

            {/* <div className="header header-fixed header-logo-app">
                <i id="ic-sidebar" className="fa fa-arrow-left back" onClick={() => this.setState({ go_back: true })} style={{padding: 20+"px"}}></i>Notifikasi
            </div>
            <br/><br/> */}

            <div class="page-title-large color-white">
                <center>
                    <img src="../tp/white.png"/>
                </center>
            </div>
            <div data-height="210" class="page-title-bg dark-mode-tint">
            </div>
            <div data-height="210" class="page-title-bg opacity-90 bg-highlight">
            </div>
            <br></br>

            <div className="content-boxed">
                <center>
                    <div style={{width: 100+'px'}}>
                        <img src="/bell.png" style={{marginTop: 20+'px', width: 50+'px'}}/>
                    </div>
                </center><br/>
                <center>
                    <h5 className="notif-transaksi">Notifikasi Transaksi</h5>
                    <div className="notif-desc">
                        <p>
                        Terima kasih atas donasi dan partisipasi anda. Update Total donasi dalam 1X24 Jam. Semoga Allah membalas dengan pahala yang berlipat.
                        </p>
                    </div>
                </center>

                <a 
                    className="payment-method" 
                    style={{padding: "10px", margin: 20+'px'}} 
                    onClick={() => this.setState({ go_home: true })}
                >Kembali Ke Home</a><br />
            </div>

            <Mitra/>

            <footer style={{margin: 10+'px', padding: 10+'px'}}>
                <center><p>Copyrights © 2020 All Rights Reserved by Teman Peduli.</p></center>
            </footer>
        </div>
        )
    }
}

export default AfterPay