import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import Header from '../Components/Header'
import { Plus, Minus } from 'react-feather'
import * as Constant from '../Constant'
import axios from 'axios'
import { MetodePembayaran, Input, Nominal } from '../Components/FormComponents';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import Select from 'react-select';

import Cookies from 'universal-cookie';
Modal.setAppElement('#root');

const customStyles = {
    content: {
        position: 'relative',
        top: '25px',
        bottom: '10px',
        right: '0px',
        width: '400px',
        border: '0px',
        borderRadius: '15px',
        zIndex: 100
    },
    overlay: {
        zIndex: 99,
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
    }
};

const cookies = new Cookies();

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchandise: [],
            image_link: '',
            loading: true,
            donation_type: [],
            total_order: 1,
            nominals: '',
            subtotal: 0,
            alamat_lengkap: 'cejhh',
            full_name: 'Ro',
            phone_number: '082112030229',
            email: 'rob@mgla.com',

            setIsOpen: false,
            checkoutModal: false,

            ekspedisi_pengiriman: [
                { value: 'jne', label: 'JNE' },
                { value: 'pos', label: 'POS Indonesia' },
                { value: 'tiki', label: 'TIKI' },
            ],
            ongkir: 0,

            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            selected_method: '',
            selected_vendor: ''
        }
    }

    componentDidMount() {
        this.getMerchandise()
    }
    getMerchandise() {
        let { match: { params } } = this.props;
        trackPromise(
            axios.get(Constant.GET_MERCHANDISE_DETAIL + params.productKey)
                .then(response => {
                    let data = response.data.data;
                    this.setState({
                        merchandise: data,
                        image_link: Constant.GET_MERCHANDISE_IMAGE + data.id
                    })
                    this.getDonationNominals()
                })
        )
    }

    getDonationNominals() {
        const { merchandise } = this.state
        let merch_id = merchandise.id
        axios.get(Constant.GET_MERCHANDISE_NOMINAL + merch_id).then(
            response => {
                if (response.data.data) {
                    this.setState({
                        donation_type: response.data.data,
                        subtotal: response.data.data.value,
                        loading: false,
                    });
                } else {
                    this.getNominal()
                }
            }
        )
    }

    getNominal() {
        axios.get(Constant.GET_NOMINAL_BUTTON).then(
            response => {
                this.setState({
                    nominals: response.data.data,
                    loading: false,
                })
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }

    onPlusButtonClicked(value) {
        const { subtotal, total_order } = this.state
    }
    onMinusButtonClicked(value) {
        const { subtotal, total_order } = this.state

    }

    callbackVendor = (callVendor, callMethod) => {
        this.setState({ selected_method: callMethod, selected_vendor: callVendor, setIsOpen: false, checkoutModal: true });
    }

    callbackNominal = (nominal) => {
        this.setState({ donation: nominal });
    }

    handleProvinceChange(event) {
        this.setState({
            province_id: event.value
        }, () => this.getRajaOngkirAPI());
    }

    handlePengirimanChange(event) {
        this.setState({
            metode_pengiriman: event.value
        });
    }

    handleCityChange(event) {
        this.setState({
            city_id: event.value
        });
    }

    callbackInput = (name, value) => {
        this.setState({ [name]: value })
    }

    onMinusButtonClicked() {
        const { total_order, donation_type } = this.state;
        let newValue = total_order - 1;
        if (newValue < 1) {
            newValue = 1;
        }
        let subtotal = newValue * donation_type.value;
        this.setState({ total_order: newValue, subtotal: subtotal })
    }

    onPlusButtonClicked() {
        const { total_order, donation_type } = this.state;
        let newValue = total_order + 1;
        let subtotal = newValue * donation_type.value;
        this.setState({ total_order: newValue, subtotal: subtotal })
    }

    onAmountChanged(e) {
        const { donation_type, total_order } = this.state
        const { name, value } = e.target
        let subtotal = donation_type.value * total_order
        let newValue = parseInt(value.split('.').join('').split(',').join(''));
        if (newValue < subtotal) {
            newValue = subtotal
        }
        this.setState({ [name]: newValue })
    }
    onSuggestButtonClicked(value) {
        const { donation_type, total_order } = this.state
        let newValue = parseInt(value.replace(/\./g, ''));
        let subtotal = donation_type.value * total_order;
        if (newValue < subtotal) {
            newValue = subtotal
            alert('Tidak bisa mengubah nilai jika lebih kecil dari subtotal')
        }
        this.setState({ subtotal: newValue })
    }

    hitungTagihan() {
        const { donation_type, total_order, subtotal,
            selected_method, selected_vendor,
            metode_pengiriman, alamat_lengkap, city_id, province_id, merchandise, postal_code,
            full_name, email, phone_number } = this.state
        if (full_name === "") {
            alert("Mohon isi nama lengkap anda");
        } else if (email === "") {
            alert("Mohon isi email anda")
        } else if (phone_number === "") {
            alert("Mohon isi nomor telepon anda");
        } else if (metode_pengiriman === "") {
            alert("Mohon pilih metode pengiriman");
        } else if (province_id === "") {
            alert("Mohon pilih provinsi anda");
        } else if (city_id === "") {
            alert("Mohon pilih kota anda");
        } else if (postal_code === "") {
            alert("Mohon isi kode pos");
        } else if (alamat_lengkap === "") {
            alert("Mohon isi alamat lengkap");
        } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            this.setState({ setIsOpen: true })
        } else {
            alert("Email tidak valid")
        }
    }

    pesan() {
        const { full_name, phone_number, email, alamat_lengkap,
            donation_type, total_order, merchandise, subtotal, selected_method, selected_vendor,
            card_number, exp_month, exp_year, cvn } = this.state

        let metode_pembayaran = null
        if (selected_vendor.payment_type == 'virtual_account') {
            metode_pembayaran = selected_vendor.midtrans_code
        } else if (selected_vendor.payment_name == "Alfamart") {
            metode_pembayaran = selected_vendor.payment_name.toUpperCase()
        } else {
            metode_pembayaran = selected_vendor.payment_name
        }

        let array = []
        if (selected_vendor.payment_type == 'credit_card') {
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }

        const parameter = {
            'key_yayasan': Constant.KEY_YAYASAN,
            'nama_lengkap': full_name,
            'email': email,
            'no_handphone': phone_number,
            'alamat_lengkap': alamat_lengkap,
            'harga': donation_type.value,
            'qty': total_order,
            'subtotal': subtotal,
            'program_id': merchandise.related_program,
            'vendor': selected_vendor.vendor,
            'payment_type': selected_vendor.payment_type,
            'payment_method': metode_pembayaran,
            'device': 'PWA',
        }

    }
    
    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    render() {
        const { merchandise, image_link, loading, donation_type, nominals, total_order,
            ekspedisi_pengiriman, metode_pengiriman, subtotal, setIsOpen, checkoutModal,
            full_name, phone_number, email, selected_method, selected_vendor,
            card_number, exp_month, exp_year, cvn } = this.state
        return (
            <div className="page-content">
                <Header back_url={'/merchandise'} />
                <div className="header header-fixed header-logo-app header-auto-show">
                    <div className="res">
                        <Link to={'/'}>
                            <i id="ic-sidebar" className="fa fa-arrow-left" style={{ padding: 20 + "px" }}></i>
                            {merchandise.product_name}
                        </Link>
                    </div>
                </div>

                {loading == false &&
                    <>
                        <div className="content-boxed" style={{ marginTop: '20px', marginBottom: '10px' }}>
                            <div className="content" id="program-list-content">
                                <div className="program-home-image" style={{ backgroundImage: `url(${image_link})` }}></div>
                                <div className="program-desc">
                                    <p style={{ marginBottom: 0 }}>Produk yang di pilih :</p>
                                    <b><p className="title-program">{merchandise.product_name}</p></b>
                                    <p style={{ marginBottom: 0 }}>Harga Satuan <b>Rp {this.numberFormat(merchandise.value)}</b></p>
                                    <p style={{ marginBottom: 0 }}>Total Order</p>
                                    <b>
                                        <div style={{ display: 'flex' }}>
                                            <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onMinusButtonClicked()} >
                                                <Minus />
                                            </a>
                                            <input type="text" name="total_order" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', marginLeft: '10px', marginRight: '10px', padding: 10 + 'px', textAlign: 'center' }} id="donation" className="input-form-donasi" value={total_order} readOnly />
                                            <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onPlusButtonClicked()}>
                                                <Plus />
                                            </a>
                                        </div>
                                    </b>
                                </div>
                            </div>
                        </div>

                        <div className="content-boxed" style={{ paddingTop: '0px' }}>
                            <section id="donasi-box">
                                <div id="btn_for_login">
                                    <br />
                                    <center style={{ fontSize: 12 + 'px', color: '#8a8a8a' }}>
                                        Silahkan isi data di bawah ini
                                    </center>
                                </div>
                                <Input type="text" name="full_name" value={full_name} placeholder="Nama Lengkap" callback={this.callbackInput} />
                                <Input type="number" name="phone_number" value={phone_number} placeholder="Nomor Ponsel atau Whatsapp" callback={this.callbackInput} />
                                <Input type="email" name="email" value={email} placeholder="Email" callback={this.callbackInput} />
                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Alamat Pengiriman</p>

                                <textarea className="input-dr-donasi" style={{ textAlign: 'left' }} placeholder="Alamat Lengkap..." onChange={(e) => this.setState({ alamat_lengkap: e.target.value })}></textarea>
                                {donation_type !== null &&
                                    <>
                                        {donation_type.type === "minimum" &&
                                            <>
                                                <p style={{ marginBottom: 0 }}>Total </p>
                                                <b><input type="type" name="subtotal" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', padding: 10 + 'px' }} className="input-form-donasi" value={this.numberFormat((subtotal) ? subtotal : 0)} onChange={(e) => this.onAmountChanged(e)} /></b>
                                                <div className="sugest-box">
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat(donation_type.value))}>
                                                        {this.numberFormat(donation_type.value)}
                                                    </a>
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat("50.000"))}>
                                                        50.000
                                                    </a>
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat("100.000"))}>
                                                        100.000
                                                    </a>
                                                    <a className="button-donasi" style={{ padding: 5 + "px" }} onClick={() => this.onSuggestButtonClicked(this.numberFormat("200.000"))}>
                                                        200.000
                                                    </a>
                                                </div>
                                                <p style={{ marginBottom: 0 }}>* Nominal lebih akan didonasikan ke program {merchandise.judul}. <Link to={'/detailprogram/' + merchandise.seo} style={{ color: '#1a0dab' }}>Klik disini untuk melihat program terkait.</Link> </p>
                                            </>
                                        }
                                        {donation_type.type === "kelipatan" &&
                                            <>
                                                <p style={{ marginBottom: 0 }}>Total </p>
                                                <b>
                                                    <div style={{ display: 'flex' }}>
                                                        <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onMinusButtonClicked(donation_type.value)}>
                                                            <Minus />
                                                        </a>
                                                        <input type="text" name="subtotal" style={{ borderRadius: 10 + 'px !important', marginBottom: 5 + 'px', marginLeft: '10px', marginRight: '10px', padding: 10 + 'px' }} id="donation" className="input-form-donasi" value={this.numberFormat((subtotal) ? subtotal : 0)} onChange={(e) => this.onAmountChanged(e)} readOnly />
                                                        <a className="button-donasi" style={{ marginTop: '0px', marginBottom: 5 + 'px', padding: 10 + 'px', height: '42px' }} onClick={() => this.onPlusButtonClicked(donation_type.value)}>
                                                            <Plus />
                                                        </a>
                                                    </div>
                                                </b>
                                                <p style={{ marginBottom: 0 }}>* Nominal lebih akan didonasikan ke program {merchandise.judul}. <Link to={'/detailprogram/' + merchandise.seo} style={{ color: '#1a0dab' }}>Klik disini untuk melihat program terkait.</Link> </p>
                                            </>
                                        }
                                    </>
                                }

                                <a className="donasi" style={{ cursor: 'pointer' }} onClick={() => this.hitungTagihan()}>Hitung Pesanan</a>
                            </section>
                        </div>

                        <Modal
                            isOpen={setIsOpen}
                            onRequestClose={() => this.setState({ setIsOpen: false })}
                            contentLabel="Detail Pembayaran"
                            style={customStyles}
                            className="menu round-medium res"
                            closeTimeoutMS={300}>
                            <div className="res" style={{ overflow: 'auto' }}>
                                <div className="filter">
                                    <h5 style={{ marginBottom: 15 + 'px' }}>Detail Tagihan</h5>
                                    <div className="content" style={{ marginLeft: '0px' }}>
                                        <p style={{ marginBottom: 0 }}>Nama Pemesan</p>
                                        <b><p style={{ fontSize: '16px', lineHeight: 'normal' }}>{full_name}</p></b>
                                        <p style={{ marginBottom: 0 }}>Nomor Telpon</p>
                                        <b><p style={{ fontSize: '14px', lineHeight: 'normal' }}>{phone_number}</p></b>
                                        <p style={{ marginBottom: 0 }}>Email</p>
                                        <b><p style={{ fontSize: '14px', lineHeight: 'normal' }}>{email}</p></b>
                                        <div style={{ display: 'flex', marginTop: '10px' }}>
                                            <img src={image_link} style={{ width: '25%' }} />
                                            <div style={{ width: '75%', marginLeft: '10px' }}>
                                                <b><p className="title-program">{merchandise.product_name}</p></b>
                                                <p style={{ marginBottom: 0 }}>Harga Satuan: <b>Rp {this.numberFormat(donation_type.value)}</b></p>
                                            </div>
                                        </div>
                                        <hr style={{ marginBottom: '10px', marginTop: '5px' }} />
                                        <center style={{ fontSize: 12 + 'px', color: '#8a8a8a', marginTop: '10px' }}>
                                            Rincian Tagihan
                                        </center>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Qty</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>{total_order}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Harga Satuan</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(donation_type.value)}</p>
                                        </div>
                                        <hr style={{ marginBottom: '0px', marginTop: '0px' }} />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Subtotal</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(donation_type.value * total_order)}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Donasi</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Rp {this.numberFormat(subtotal - (donation_type.value * total_order))}</p>
                                        </div>
                                        <hr style={{ marginBottom: '0px', marginTop: '0px', borderColor: '#ddd' }} />
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>Total</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>Rp {this.numberFormat(subtotal)}</p>
                                        </div>

                                    </div>

                                    <MetodePembayaran callback={this.callbackVendor} />
                                </div>
                            </div>
                        </Modal>


                        <Modal
                            isOpen={checkoutModal}
                            onRequestClose={() => this.setState({ checkoutModal: false })}
                            contentLabel="Detail Pembayaran"
                            style={customStyles}
                            className="menu round-medium res"
                            closeTimeoutMS={300}>
                            <div className="res" style={{ overflow: 'auto' }}>
                                <div className="filter">
                                    <h5 style={{ marginBottom: 15 + 'px' }}>Konfirmasi Pembayaran</h5>
                                    <div className="content" style={{ marginLeft: '0px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Metode Pembayaran</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>{selected_vendor.payment_name}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ marginBottom: 0, fontSize: '14px' }}>Total</p>
                                            <p style={{ marginBottom: 0, fontSize: '14px', fontWeight: 'bold' }}>Rp {this.numberFormat(subtotal)}</p>
                                        </div>
                                        <p style={{ marginBottom: 0, fontSize: '12px' }}>* Ongkos kirim ditanggung oleh pemesan</p>
                                    </div>
                                    <a className="donasi" style={{ cursor: 'pointer' }} onClick={() => this.pesan()}>Bayar Sekarang</a>
                                </div>
                            </div>
                        </Modal>

                    </>
                }

            </div>
        )
    };
}

export default Checkout;